
import React from 'react';
import moment from "moment"
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
} from 'devextreme-react/data-grid';



const onRowPre = (e) => {
  if (e.rowType == "header") {
    //   e.rowElement.style.display = "none";
    e.rowElement.style.backgroundColor = "black !important";
    e.rowElement.style.fontSize = "1rem";
    e.rowElement.style.lineHeight = "1.5rem";
    //     font-size: 1rem;
    // line-height: 1.5rem;

  }
  if (e.rowType == "data") {
    e.rowElement.style.margin = "15px";
    e.rowElement.style.fontStyle = "light";
    e.rowElement.style.color = "#3D3D3D";
    e.rowElement.style.lineHeight = "35px";
    e.rowElement.style.fontWeight = 400;
    e.rowElement.style.fontSize = '16px';
    if (e.rowIndex % 2 === 0) {
      e.rowElement.style.backgroundColor = "#E1F1FF";
    }
  }

};

const QueryDetails = ({ detailsList,windowSize }) => {


  const [collapsed, setCollapsed] = React.useState(false)
  const onContentReady = (e) => {
    if (!collapsed) {
      e.component.expandRow(['EnviroCare']);
      setCollapsed(true)
    }
  }


  const viewDate = (date)=>{
    let newdate = date.replace(/\//g, "-")
    newdate = newdate.split("-")
    newdate = newdate[2] + "-" + newdate[1]+ "-"+ newdate[0]
    
    newdate = moment(new Date(newdate)).format("DD/MM/yyyy")
   return <p>{newdate}</p>
 }

  const TableHead = ["QueryID", "SrNo", "Message", "Date", "Time", "InOut", "Mode", "FromTo"]
  const RenderMsg = (msg) => {
    return <p dangerouslySetInnerHTML={{ __html: msg }}></p>
  }
  return (
    <>
      <DataGrid
        dataSource={detailsList}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        onContentReady={onContentReady}
        onRowPrepared={onRowPre}
        wordWrapEnabled={true}
        keyExpr="Message"

        // width={1075}

      >
        {/* <GroupPanel visible={true} /> */}
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        {/* <Grouping autoExpandAll={false} /> */}

        {/* <Column dataField="QueryID" caption="Querie ID"  /> */}
        <Column
          dataField="Message"
          caption="Message"
          hidingPriority={windowSize <= 768 && 0}
          dataType="string"
          alignment="left"
          width={'80%'}
          cellRender={(e) => RenderMsg(e.data.Message)}
          allowSorting={false}
        />
        <Column
          dataField="QueryID"
          caption="Date"
          hidingPriority={windowSize <= 768 && 2}
          dataType="date"          
          defaultSortOrder={"desc"}
          alignment="left"
          cellRender={(e) => viewDate(e.data.Date)}
          width={130}
        />
        <Column
          dataField="Time"
          caption="Time"
          hidingPriority={windowSize <= 768 && 1}
          dataType="time"
          alignment="left"
          width={110}

        />
        {/* <Column
          dataField="Made"
          caption="Made"
          dataType="string"
          alignment="left"
          width={130}
        /> */}
        <Column
          dataField="FromTo"
          caption="From To"
          // hidingPriority={windowSize <= 768 && 0}
          dataType="string"
          alignment="left"
          width={'220px'}
        />
        <Pager allowedPageSizes={'10'} showPageSizeSelector={false} />
        <Paging defaultPageSize={10} />
      </DataGrid>
      {/* <table className=''>
                <thead className='text-xl text-white uppercase bg-[#3491FF]'>
                    <tr className=''>
                        {TableHead.map((Header)=>(
                            <th className='px-6 py-3 text-center lg:text-base text-sm'>{Header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {detailsList.map((SingleData)=>(
                    <tr>
                        {TableHead.map((Header)=>(
                            <td className='px-4 py-4 border text-[#3D3D3D] lg:text-lg text-sm font-medium' 
                            dangerouslySetInnerHTML={{__html: SingleData[Header]}}
                            ></td>
                        ))}
                    </tr>
                    ))}
                </tbody>
            </table> */}

    </>
  )
}


export default QueryDetails;