import React,{useState,useEffect} from 'react'
import CommexTable from './CommexTable'
import CrossTable from './CrossTable'
import EstroTable from './EstroTable'
import MyProfileForm from './MyProfileForm'
import ProductTable from './ProductTable'
import PMSSection from './PMSSection';
import Table from './Table'
import OthersTable from './OthersTable'
import TradePlusTable from './TradePlusTable'
import CommexTab from './CommexTab'

const ProductTabs = ({TradePlus,data}) => {
    const [TabsButton, setTabsButton] = useState("TradePlus")
    const [windowSize, setWindowSize] = useState(getWindowSize());


    useEffect(() => {
      function handleWindowResize() {
        setWindowSize(getWindowSize());
      }
  
      window.addEventListener('resize', handleWindowResize);
  
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }, []);

    function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

    const tabs = [
        {name:"TradePlus",components:<MyProfileForm />},
        {name:"Estro",components:<MyProfileForm />},
        {name:"Cross",components:<MyProfileForm />},
        {name:"Commex",components:<MyProfileForm />},
        {name:"Associated Modules",components:<MyProfileForm />},
        // {name:"PMS",components:<MyProfileForm />},
    ]
  return (
    <div>
        <div className='w-full'>
        <ul className='border rounded-lg w-full 2xl:flex xl:flex lg:flex md:flex flex-wrap'>  
           {tabs.map((data)=>{
            return (<div className="mr-2 2xl:w-[19%] xl:w-[19%] lg:w-[19%] md:w-[19%] w-[100%]">
                <div  className={`inline-block py-3 px-2 rounded-lg 2xl:m-1.5 xl:m-1.5 lg:m-1.5 md:m-1.5 mt-1.5 w-full text-center 2xl:text-[20px] xl:text-[18px] lg:text-[18px] md:text-[14px] text-[16px] cursor-pointer ${TabsButton==data.name ? 'active bg-[#3491FF] text-white':'hover:bg-gray-100'}`}  
                aria-current="page" onClick={()=>{setTabsButton(data.name)}} >{data.name}</div>
                </div>)
           })}  
        </ul>
      </div>
      <div>
      <div>
        <>
      { windowSize?.innerWidth >=769  && (TabsButton=="TradePlus"?
      <ProductTable tableData={TradePlus} data={data.TradePlus} windowSize={windowSize?.innerWidth}/>:TabsButton=="Commex"?
      <CommexTable data={data.Commex} windowSize={windowSize?.innerWidth}/>:TabsButton=="Cross"?
      <CrossTable data={data.Cross}  windowSize={windowSize?.innerWidth} />:TabsButton=="Estro"?
      <EstroTable data={data.Estro} windowSize={windowSize?.innerWidth}/>:TabsButton=="Associated Modules"?
      <OthersTable data={data} windowSize={windowSize?.innerWidth}/>:TabsButton=="PMS"?
      <PMSSection/>:<></>)}  
</> 
<>
      {windowSize?.innerWidth <=769 &&  (TabsButton=="TradePlus"?
      <TradePlusTable tableData={TradePlus} data={data.TradePlus} windowSize={windowSize?.innerWidth}/>:TabsButton=="Commex"?
      <CommexTab data={data.Commex} windowSize={windowSize?.innerWidth}/>:TabsButton=="Cross"?
      <CrossTable data={data.Cross}  windowSize={windowSize?.innerWidth} />:TabsButton=="Estro"?
      <EstroTable data={data.Estro} windowSize={windowSize?.innerWidth}/>:TabsButton=="Associated Modules"?
      <OthersTable data={data} windowSize={windowSize?.innerWidth}/>:TabsButton=="PMS"?
      <PMSSection/>:<></>)}  
</> 
    </div>
      </div>
    </div>
  )
}

export default ProductTabs