import React from "react";
import "./singleInvoice.scss";
import Microsoftimg from "../../../../components/common/Images/MicrosoftTeams-image (1).png";
import Dot from "../../../../components/common/Images/Dot.svg";
import parse from "html-react-parser";

const SingleInvoice = ({ BillInfo }) => {
  let adress_data = {
    a: "CIN No : L67190MH2011PLC220404",
    b: "36/227, RDP 10, Sector 6 Charkop, Kandivali (West)",
    c: "Mumbai - 400 067",
    d: "E-mail: info@Tplus.in",
    e: "URL: www.Tplus.in",
  };
  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };
  const [data, setData] = React.useState("");

  React.useEffect(() => {
    try {
      let data1 = JSON.parse(localStorage.getItem("ProfileTab"));
      setData(data1);
    } catch (e) {}
  }, []);

  function stripHtml(html) {
    if (!html) return "";

    html = html.toString().replace("&nbsp", "");
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;

    var text = tmp.textContent || tmp.innerText || "";

    text = text.replace("nbsp;", "");

    return text;
  }

  return (
    <>
      <div className="p-[30px]">
        <div className="flex justify-end px-[12px]">
          <div
            className=" ml-auto d-block  "
            style={{
              fontWeight: 700,
              fontSize: "30px !important",
              marginRight: "2rem",
              padding: "0rem",
            }}
          >
            {BillInfo?.BillNo.includes("Proforma") && (
              <strong>Proforma Invoice</strong>
            )}
          </div>
          <img src={Microsoftimg} className="w-[35%]" />
        </div>
        <div className="flex justify-end w-[100%]">
          <div className="">
            <div className=" text-md flex justify-end" align="right">
              CIN No : <b>L67190MH2011PLC220404</b>
            </div>
            <div className=" text-md justify-end" align="right">
              36/227, RDP 10, Sector 6
            </div>
            <div className=" text-md flex justify-end" align="right">
              Charkop, Kandivali (West)
            </div>
            <div className=" text-md flex justify-end mr-[5px]" align="right">
              <div align="right">Mumbai - 400 067</div>
            </div>

            {BillInfo?.Email && (
              <div className=" text-md flex justify-end" align="right">
                E-mail:{" "}
                <span className="font-bold ml-3">{BillInfo?.Email}</span>
              </div>
            )}
            {BillInfo?.URL && (
              <div className=" text-md flex justify-end" align="right">
                URL: <span className="font-bold ml-6"> {BillInfo?.URL}</span>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="px-[15px]">
            <table className="w-[100%] body-table">
              <tr>
                <td
                  className="border border-black w-[60%]"
                  rowSpan={
                    BillInfo.Description.includes("Annual Maintenance Charges")
                      ? 3
                      : 4
                  }
                >
                  <td rowSpan={4} className="text-align-top pl-2">
                    {BillInfo?.CompanyName}
                    <br />
                    {BillInfo?.Add1}
                    <br />
                    {BillInfo?.Add2}
                    <br />
                    {BillInfo?.Add3}
                    {BillInfo?.City}-{BillInfo?.Pin}
                    <br />
                    <br />
                    Your GST ID: {BillInfo?.YourGSTID.trim()}
                    {BillInfo?.BillRemark && (
                      <>
                        <br />
                        {BillInfo?.BillRemark}
                      </>
                    )}
                  </td>
                </td>
                <td className="border border-black" colSpan={2}>
                  <div className="flex justify-center font-bold text-lg  p-[10px]">
                    {BillInfo?.BillNo}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border border-black" align="right">
                  Date : &nbsp;
                </td>
                <td className="border border-black" align="right">
                  {BillInfo?.BillDate}
                </td>
              </tr>
              <tr>
                <td className="border border-black" align="right">
                  Due Date From :
                </td>
                <td className="border border-black" align="right">
                  {BillInfo?.FromDate}
                </td>
              </tr>
              {BillInfo.Description.includes("Annual Maintenance Charges") ? (
                ""
              ) : (
                <tr>
                  <td className="border border-black" align="right">
                    Installation : &nbsp;
                  </td>
                  <td className="border border-black" align="right">
                    {BillInfo?.ToDate}
                  </td>
                </tr>
              )}

              <tr>
                <td colSpan={2} className="border border-black">
                  <div className="flex justify-start font-bold text-lg  p-[10px]">
                    Description
                  </div>
                </td>
                <td className="border border-black" align="right">
                  Rupees
                </td>
              </tr>

              <tr>
                <td
                  colSpan={2}
                  className="border border-black p-[10px] max-h-4"
                >
                  <div
                    className="container"
                    dangerouslySetInnerHTML={{ __html: BillInfo.Description }}
                  />
                  {/* {stripHtml(BillInfo.Description)} */}
                </td>
                <td className="border border-black" align="right">
                  {BillInfo.Amount}
                </td>
              </tr>

              {BillInfo?.SGST ? (
                <tr>
                  <td colSpan={2} className="border border-black" align="right">
                    SGST Collected @ 9%
                  </td>
                  <td className="border border-black" align="right">
                    {BillInfo.SGST}
                  </td>
                </tr>
              ) : (
                ""
              )}

              {BillInfo?.CGST ? (
                <tr>
                  <td colSpan={2} className="border border-black" align="right">
                    CGST Collected @ 9%
                  </td>
                  <td className="border border-black" align="right">
                    {BillInfo.CGST}
                  </td>
                </tr>
              ) : (
                ""
              )}

              {BillInfo?.IGST ? (
                <tr>
                  <td colSpan={2} className="border border-black" align="right">
                    IGST Collected @ 18%
                  </td>
                  <td className="border border-black" align="right">
                    {BillInfo.IGST}
                  </td>
                </tr>
              ) : (
                ""
              )}

              {BillInfo?.UGST ? (
                <tr>
                  <td colSpan={2} className="border border-black" align="right">
                    UGST Collected @ 9%
                  </td>
                  <td className="border border-black" align="right">
                    {BillInfo.UGST}
                  </td>
                </tr>
              ) : (
                ""
              )}

              <tr>
                <td colSpan={2} className="border border-black">
                  <div className="flex justify-start text-lg  p-[10px]">
                    {BillInfo.TotalInWords}
                  </div>
                </td>
                <td className="border border-black" align="right">
                  {BillInfo.Total}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div className="px-[15px]">
          <div>
            <div className="flex justify-between w-[99%]  text-md">
              <div>E&OE</div>

              <div>
                {JSON.parse(localStorage.getItem("ProfileTab")).ClientCode}
              </div>
            </div>
            {BillInfo.BillRemark?.length > 0 ? (
              <h6 style={{ margin: "10px 0px" }}>
                SUPPLY MEANT FOR EXPORT/SUPPLY TO SEZ UNIT OR SEZ DEVELOPER FOR
                AUTHORISED OPERATIONS UNDER BOND OR LETTER OF UNDERTAKING
                WITHOUT PAYMENT OF INTEGRATED TAX
              </h6>
            ) : (
              ""
            )}

            <div className="leading-3 my-3">
              <span className="font-bold">{BillInfo?.GSTText}</span>
            </div>
            <div className="font-bold leading-3 text-md	">
              {BillInfo?.CategoryText}
            </div>
            <div className="flex justify-between">
              <div>
                <ul>
                  <li className=" leading-3 flex">
                    <img
                      src={Dot}
                      className="mr-2 mt-1 w-[5px] h-[5px]"
                      alt=""
                    />
                    <span>
                      This document is for intimation and verification only.
                    </span>
                  </li>
                  <li className=" leading-3 flex">
                    <img
                      src={Dot}
                      className="mr-2 mt-1  w-[5px] h-[5px]"
                      alt=""
                    />
                    <span>
                      Actual bill will be prepared and forwarded on receipt of
                      Payment.*
                    </span>
                  </li>
                  <li className=" ">
                    <div className="leading-3  flex">
                      <img
                        src={Dot}
                        className="mr-2 mt-1  w-[5px] h-[5px]"
                        alt=""
                      />
                      Our Bank A/c Details: State Bank of India,Borivali West
                      Branch
                    </div>
                    {/* <br/> */}
                    <div className="ml-3 leading-3">
                      {" "}
                      IFSC Code: SBIN0000551
                    </div>
                    <div className="ml-3 leading-3">
                      {" "}
                      Current A/c No.: 39744127423
                    </div>
                  </li>
                  <li className=" leading-3 flex">
                    <img
                      src={Dot}
                      className="mr-2 mt-1  w-[5px] h-[5px]"
                      alt=""
                    />
                    <div>
                      Applicable TDS may be deducted {/* {BillInfo?.PanNo} */}
                      <div size={2}>(PAN: AAQCS1732G )</div>
                    </div>
                  </li>
                  <li className=" leading-3 flex">
                    <img
                      src={Dot}
                      className="mr-2 mt-1  w-[5px] h-[5px]"
                      alt=""
                    />
                    <span>Subject to Mumbai Jurisdiction only.</span>
                  </li>
                  <li className=" leading-3 flex">
                    <img
                      src={Dot}
                      className="mr-2 mt-1  w-[5px] h-[5px]"
                      alt=""
                    />
                    <small>
                      This being computer generated statement, not signed.
                    </small>
                  </li>
                </ul>
              </div>

              <div>
                <b className="font-bold">SECMARK CONSULTANCY LTD.</b>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {BillInfo?.Annexure ? (
          <div className="customHtml">
            <div className="breack"></div>
            {parse(`${BillInfo.Annexure}`, options)}
          </div>
        ) : (
          ""
        )}
        {/* {
          parser.parseFromString(BillInfo?.Annexure, 'text/html')
        } */}
      </div>
    </>
  );
};
export default SingleInvoice;
