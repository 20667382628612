import React, { useState, useEffect } from "react";
import SliderCard from "./SliderCard";
import Slider from "react-slick";
import axios from "axios";
import fileDownload from "js-file-download";
import CommexPopUp from "./CommexPopUp";
import moment from "moment";

const BackArrow = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      class="back-forword-btn bg-white md:-m-7 -m-5	absolute z-50	 ext-blue-700 border border-blue-700 hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 left-[12%]"
    >
      <svg
        className="stroke-[#3491ff] hover:stroke-[#ffffff]  "
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};
const ForwardArrow = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      class="back-forword-btn  bg-white m-3 z-50 text-blue-700 border border-blue-700 hover:bg-[#3491FF]   hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute right-[4%]"
    >
      <svg
        className="stroke-[#3491ff] hover:stroke-[#ffffff]"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};
const CommexDownload = (props) => {

  const { dateData } = props

  const [checkObj, setCheckObj] = useState({});
  const [model, setModal] = useState("");

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    nextArrow: <ForwardArrow />,
    prevArrow: <BackArrow />,
  };
  useEffect(() => {
    let Data = JSON.parse(localStorage.getItem("ProfileTab"));
    let check = Data?.["Commex"];
    setCheckObj(check);
  }, []);
  const DownloadZipFile = async (zippedFile, fileName = "ZippedData.zip") => {
    
    if (zippedFile) {
      let DownloadFile = "/downloads/" + zippedFile;
      axios.get(DownloadFile, { responseType: "blob" }).then((res) => {
        fileDownload(res.data, fileName);
      });
    }

    //   const blobPromise =  fetch(zippedFile)
    // .then(function (response) {
    //     if (response.status === 200 || response.status === 0) {
    //         return Promise.resolve(response.blob());
    //     } else {
    //         return Promise.reject(new Error(response.statusText));
    //     }
    // })

    //   let blob = new Blob([blobPromise], { type: 'application/zip' })

    //   const downloadUrl = URL.createObjectURL(blob)
    //   let a = document.createElement("a");
    //   a.href = downloadUrl;
    //   a.download = fileName;
    //   document.body.appendChild(a);
    //   a.click();
  };
  return (

    <>
    
    {checkObj.Commex === "Y" && (<div className="test-back">
      
        <>
        <div className="py-10">
        <div >
            <div className="font-medium lg:text-3xl md:text-3xl text-[25px] mb-4	text-center text-[#0A2A4A]">
              Commex
            </div>
            <div className="mb-6 lg:text-[18px] md:text-[18px] text-[16px] text-center text-[#808D9A] font-normal px-[1rem] md:px-[10rem]">
              Commex supports all the back office operations of commodity
              trades.{" "}
            </div>
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 justify-between mx-10 download-sliders">
            {![checkObj.Commex, checkObj.ESign].every((d) => d == "N") && (
            
                <div>
                  <h2 className=" text-white text-lg text-center rounded-tl rounded-bl bg-[#3491FF] py-3 m-0 ">
                    Head office
                  </h2>
                  <div className="table-auto w-full  whitespace-no-wrap p-0 m-0  "> <div className="bg-[#3491]  odd-color">
                  <div className={`${checkObj?.Commex=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          clickToDownload
                          title="Head office"
                          subTitle="Commex"
                          date={dateData.length > 0 &&( dateData?.find(a => a.Product == "Commex")?.ReleasedOn ||"not")}
                          // disabled={dateData.length > 0 &&( moment(dateData.find(a=> a[1] == "commex/commex.zip")[3]).isBefore('1999-12-31') }

                          img={"/assets/img/svg-1.svg"}
                          arrow={true}
                          download={"/download/commex/commex.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          buttonTxt={checkObj?.Commex}
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.Commex=="N" && "hidden"}`}>
                      <div>
                      <SliderCard
                        clickToDownload
                        title="Head office"
                        subTitle="DB Support"
                        date={dateData.length > 0 &&( dateData?.find(a => a.Product == "CommexDBSupport")?.ReleasedOn ||"not")}
                        // disabled={dateData.length > 0 &&( moment(dateData.find(a=> a[1] == "commex/commexdb.zip")[3]).isBefore('1999-12-31') }

                        img={"/assets/img/svg-1.svg"}
                        arrow={true}
                        
                        download={"/download/commex/commexdb.zip"}
                        DownloadZipFile={props.DownloadZipFile}
                        buttonTxt={checkObj?.Commex}
                      />
                      </div>
                    </div>
                    <div className={`${checkObj?.ESign=="N" && "hidden"}`}>
                      <div>
                      <SliderCard
                        clickToDownload
                        title="Head office"
                        subTitle="E-Sign"
                        date={dateData.length > 0 &&( dateData?.find(a => a.Product == "CommexESign")?.ReleasedOn ||"not")}
                        // disabled={dateData.length > 0 &&( moment(dateData.find(a=> a[1] == "Esignpro/esignprocommex.zip")[3]).isBefore('1999-12-31') }

                        img={"/assets/img/svg-1.svg"}
                        arrow={true}
                        download={"/download/Esignpro/esignprocommex.zip"}
                        DownloadZipFile={props.DownloadZipFile}
                        buttonTxt={checkObj?.ESign}
                      />
                      </div>
                    </div>
                    
                  </div></div>
                 
                </div>
  )}
            
          
            {/* <div className='relative'> */}
            {/* <h2 className=" text-center md:text-2xl text-[18px] font-medium text-[#000000]">
            Branch & Individual Client
          </h2> */}
            {/*  */}
            {/* <SliderCard title='Branch & Individual Client' subTitle='COMMNET' img={'/assets/img/svg-7.svg'} arrow={false} index={props.index} download={''} DownloadZipFile={props.DownloadZipFile} /> */}
            {/* <SliderCard title='Branch & Individual Client' subTitle='P7 Reader Uttility' img={'/assets/img/svg-7.svg'} arrow={false} index={props.index} download={''} DownloadZipFile={props.DownloadZipFile} /> */}
            {/*  */}
            {/* </div> */}
            {checkObj?.Commex == "Y" && (
              <div className="">
                <h2 className=" text-white text-lg text-center rounded-tr rounded-br bg-[#3491FF] py-3 m-0 " > Knowledge Base</h2>
                <div className="table-auto w-full  whitespace-no-wrap p-0 m-0 border-l-2 "> <div className="bg-[#3491]  odd-color" >
                <div className={`${checkObj?.Commex=="N" && "hidden"}`}>
                      <div> 
                      <SliderCard
                        clickToDownload
                        title="Knowledge Base"
                        subTitle="Circulars"
                        img={"/assets/img/svg-6.svg"}
                        arrow={false}
                        download={""}
                        section="X"
                        buttonTxt={checkObj?.Commex}
                        view
                    />
                      </div>
                    </div>
                    <div className={`${checkObj?.Commex=="N" && "hidden"}`}>
                      <div> 
                      <SliderCard
                  clickToDownload
                  title="Knowledge Base"
                  subTitle="Help"
                  img={"/assets/img/svg-6.svg"}
                  arrow={false}
                  download={true}
                  dtitle={"commex"}
                  DownloadZipFile={props.DownloadZipFile}
                  buttonTxt={checkObj?.Commex}
                  view
                />
                      </div>
                    </div>
                    <div className={`${checkObj?.Commex=="N" && "hidden"}`}>
                      <div> 
                      <SliderCard
                  clickToDownload
                  title="Knowledge Base"
                  subTitle="Modifications"
                  img={"/assets/img/svg-6.svg"}
                  arrow={false}
                  download={""}
                  section="X"
                  buttonTxt={checkObj?.Commex}
                  view
                />
                      </div>
                    </div>
                    </div>
                  </div>
               
              
               
                {/* <SliderCard clickToDownload title='Knowledge Base' subTitle='DB Support' img={'/assets/img/svg-1.svg'} arrow={true} download={'/assets/zip/commexdb.zip'} DownloadZipFile={DownloadZipFile} buttonTxt={checkObj?.Commex}/> */}
              </div>
            )}
          </div>
          {/* <CommexPopUp
            openModal={model}
            onclose={() => {
              setModal(false);
            }}
            checkObj={checkObj}
            DownloadZipFile={props.DownloadZipFile}
          /> */}
        </div>
        </>
      
    </div>)}

</>
  );
};
export default CommexDownload;