import React from 'react'
import DataGrid, {Column,Grouping,GroupPanel,Pager,Paging,FilterPanel,FilterRow,SearchPanel,HeaderFilter} from 'devextreme-react/data-grid';

const BillTable = ({BillInfo}) => {
 
  return (
    <table className='w-[100%]'>
    <thead className='ring-0 border border-black bg-white text-black'> 
            <th className='rounded-tl-2xl px-6 py-3 text-[12px] p-[10px] font-medium text-left font-bold text-left'>Description</th>
            <th className='rounded-tr-2xl px-[10px] py-3  text-[12px] p-[10px] font-medium text-right font-bold'>Rupees</th>    
    </thead>
    <tbody className=''>
        <tr className='border border-black border-bottom-style'>
            <th className='border-r border-black text-[12px] p-[10px] text-left' dangerouslySetInnerHTML={{__html: BillInfo.Description}}></th>
            <th className='text-[12px] p-[10px]'>{BillInfo.Amount}</th>
        </tr>
        <tr className='border border-black border-bottom-style'>
            <th className='border-r border-black text-[12px] p-[10px] text-left	'>Your GST ID:<span>{BillInfo.YourGSTID}</span></th>
            
        </tr>
        {BillInfo?.SGST ?<tr className='border border-black border-bottom-style'>
            <th className='border-r border-black text-[12px] p-[10px] text-left	'>SGST Collected @ 9%</th>
            <th className='text-[12px] p-[10px]'>{BillInfo.SGST}</th>
        </tr>:""}
        {BillInfo?.CGST ?<tr className='border border-black border-bottom-style'>
            <th className='border-r border-black text-[12px] p-[10px] text-left	'>CGST Collected @ 9%</th>
            <th className='text-[12px] p-[10px]'>{BillInfo.CGST}</th>
        </tr>:""}
        {BillInfo?.IGST ?<tr className='border border-black border-bottom-style'>
            <th className='border-r border-black text-[12px] p-[10px] text-left	'>IGST Collected @ 18%</th>
            <th className='text-[12px] p-[10px]'>{BillInfo.IGST}</th>
        </tr>:""}
        {BillInfo?.UGST ?<tr className='border border-black border-bottom-style'>
            <th className='border-r border-black text-[12px] p-[10px] text-left	'>UGST Collected @ 9%</th>
            <th className='text-[12px] p-[10px]'>{BillInfo.UGST}</th>
        </tr>:""}
        <tr className='border border-black'>
            <th className='border-r border-black text-[12px] p-[10px] text-left	'>{BillInfo.TotalInWords}</th>
            <th className='text-[12px] p-[10px]'>{BillInfo.Total}</th>
        </tr>
    </tbody>
</table>
  )
}

export default BillTable