import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Group1 from "../../components/common/Images/Group.png"
import Group2 from "../../components/common/Images/Group2.png"
import Group3 from "../../components/common/Images/Frame.png"
import { LazyLoadImage } from "react-lazy-load-image-component";

const Banner = () => {
  // useEffect(()=>{
  //   right !==2 &&  setTimeout(()=> {
  //     handleLeftIcon()
  //   },2000)
  // },[left])

  const [left, setLeft] = useState(0)
    const [right, setRight] = useState(1)


  const handleLeftIcon = () => {
    setLeft(left + 1)
    setRight(right - 1)
  }

  const handleRightIcon = () => {
    setLeft(left - 1)
    setRight(right + 1)

  }

  const BackArrow = (props) => {
    return (
      <button type="button" onClick={() => { props.onClick(); handleLeftIcon() }} className={`${(left < 3 && left < 0) ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -m-8  z-50 text-blue-700 border hover:bg-[#ffffff]  shadow-lg hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute left-[-5%] top-[50%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-8 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 left-[-5%] top-[50%]"}`}>
        <svg width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    )
  }

  const ForwardArrow = (props) => {
    return (
      <button type="button" onClick={() => { props.onClick(); handleRightIcon() }} className={`${right > 0 && right < 3 ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -m-8  z-50 text-blue-700 border hover:bg-[#ffffff]  shadow-lg hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute right-[-5%] top-[50%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-8 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 right-[-5%] top-[50%]"}`}>
        <svg className="" width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    )
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 1,
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    nextArrow: <ForwardArrow />,
    prevArrow: <BackArrow />,
  };
  return (
    <div className="hero-banner  bg-[#F5FAFE] px-12">
      <div className="container flex flex-wrap justify-between items-center    mx-auto">
        <main className="py-2 md:py-10  ">
          <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-2  my-5 lg:text-left md:text-left  lg:items-left md:items-left items-center text-center">
            <div className="  lg:text-left center-style md:text-left text-center">
              <h1
                className="lg:text-4xl text-2xl leading-8 tracking-tight text-[#3491FF] sm:text-2xl md:text-2xl font-medium md:leading-8 sm:mt-5  sm:max-w-xl sm:mx-auto md:mt-5  lg:mx-0  md:text-left text-center
                ">
               Stock Broking and Depository Backoffice Applications.
              </h1>
              <p className="mt-3 text-base  text-gray-500 leading-7 sm:mt-5 sm:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 font-medium  md:text-left text-center">
One of the prime software solutions of SecMark which is designed to provide post Trade Operations for Brokers and DPs.

              </p>
            </div>
            <div className="lg:ml-[100px] lg:w-[80%] md:ml-[10px] md:w-[100%] sm:ml-[20px] sm:w-[10%] ">
              <Slider {...settings}>
                <div>
                  <div className=" mb-10 mt-[19px]">
                    <p className="text-[#3491FF] font-semibold  text-center flex justify-center text-[25px] m-0  ">
                      User Privileges</p>
                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#3491FF] rounded border-0  "></hr>
                  </div>
                  <LazyLoadImage
                    src={Group2}
                    alt={Group2}
                    className="w-[100%]" effect="blur"
                  />
                </div>
                <div>
                <div className=" mb-10 mt-[19px]">
                    <p className="text-[#3491FF] font-semibold  text-center flex justify-center text-[25px] m-0  ">
                    User Friendly</p>
                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#3491FF] rounded border-0  "></hr>
                  </div>
                  <LazyLoadImage
                    src={Group3}
                    alt={Group3} effect="blur"
                    className="w-[100%]"
                  />
                </div>
                <div>
                <div className=" mb-10 mt-[19px]">
                    <p className="text-[#3491FF] font-semibold  text-center flex justify-center text-[25px] m-0  ">
                    User Defined Reports </p>
                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#3491FF] rounded border-0  "></hr>
                  </div>
                  <LazyLoadImage
                    src={Group1}
                    alt="banner-img" effect="blur"
                    className="w-[100%]"
                  />
                </div>
                {/* <img
                src="/assets/img/banner-img.svg"
                alt="banner-img"
                className="w-100"
              /> */}
              </Slider>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default Banner;



// 01 11 10

