import React, { useEffect, useState } from 'react';
import { PostAPI, GetAPI } from '../../../hooks/Service';
import Paginate from './Pagination';
import DataGrid, {
    Column,
    Grouping,
    GroupPanel,
    Pager,
    Paging,
    FilterPanel,FilterRow,
    SearchPanel,HeaderFilter
} from 'devextreme-react/data-grid';
import moment from "moment"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import QueryDetails from './QueryDetails';


const onRowPre = (e) => {
    if (e.rowType == "header") {
        //   e.rowElement.style.display = "none";
        e.rowElement.style.backgroundColor = "black !important";
        e.rowElement.style.fontSize = "1rem";
        e.rowElement.style.lineHeight = "1.5rem";
        //     font-size: 1rem;
        // line-height: 1.5rem;
    }
    if (e.rowType == "data") {
        e.rowElement.style.margin = "15px";
        e.rowElement.style.fontStyle = "medium";
        e.rowElement.style.color = "#3D3D3D";
        e.rowElement.style.lineHeight = "35px";
        e.rowElement.style.fontWeight = 400;
        if (e.rowIndex % 2 === 0) {
            e.rowElement.style.backgroundColor = "#E1F1FF";
        }
    }
};


const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    
};
const MyQueryTable = (props) => {
    const [QueriesData, setQueries] = useState([]);
    const [pagData, setPageData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [loading,setLoading] = useState(false)
    const [message, setMessage] = useState(false)
    const [windowSize, setWindowSize] = useState(getWindowSize());
    // const [check, setCheck] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

      function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }

    useEffect(() => {
        let token = localStorage.getItem('trade_plus_token');
        setLoading(true)
        const CallFun = async () => {
            let headers = {
                Authorization: "Bearer " + token
            }
            let params = '';
            let resultResponse = await GetAPI('Tplus/MyQueries', params, headers);
            if (resultResponse.status) {
                props.setcheck(true)
                setQueries(resultResponse.result)
                setLoading(false)
            }
            else{
                setLoading(false)
                // setMessage(true)
            }
        }
        if (token) {
            CallFun();
        }
    }, [])
    const [detailsList, setDetailsList] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const FetchDetails = async (id) => {
        if (id) {
            setSelectedId(id)
            let token = localStorage.getItem('trade_plus_token');
            let headers = {
                Authorization: "Bearer " + token
            }
            let params = { queryID: id };
            let resultResponse = await PostAPI('Tplus/Query', params, headers);

            if (resultResponse.status) {
                setDetailsList(resultResponse.result.Data)
                handleOpen();
            }
        }
    }
    function createData(QuerieID, Department, Subject, Status, Date, Time, Type, RaisedBy) {
        return { QuerieID, Department, Subject, Status, Date, Time, Type, RaisedBy };
    }
    useEffect(() => { pageChange(1) }, [QueriesData])
    const pageChange = (page) => {
        var start = ((page - 1) * 10);
        var end = start + 10;
        let data = [...QueriesData].slice(start, end);
        setPageData(data)
    }
    const [collapsed, setCollapsed] = useState(false)
    const onContentReady = (e) => {
        if (!collapsed) {
            e.component.expandRow(['EnviroCare']);
            setCollapsed(true)
        }
    }

    const ViewDetails = (id) => {
        return <p style={{ fontSize: '18px', paddingRight: '12px' , fontWeight: '600' }} onClick={() => FetchDetails(id)}
            className="text-blue-500 cursor-pointer lg:text-[18px] md:text-[18px] text-[14px]">View</p>;
    }
   

    const viewDate = (date)=>{
       let newdate = date.replace(/\//g, "-")
       newdate = newdate.split("-")
       newdate = newdate[2] + "-" + newdate[1]+ "-"+ newdate[0]
       
       newdate = moment(new Date(newdate)).format("DD/MM/yyyy")
      return <p>{newdate}</p>
    }
    return (
        <div>
            <div className="relative overflow-x-auto shadow-lg sm:rounded-lg ">
                {loading ?(
                    <div role="status" className="position-loading">
                    <svg
                      aria-hidden="true"
                      className="flex w-12 h-12 items-center justify-center text-gray-200 animate-spin fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                )
                :(<DataGrid
                    dataSource={QueriesData}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    onContentReady={onContentReady}
                    onRowPrepared={onRowPre}
                    keyExpr="QueryID"
                    // width={windowSize?.innerWidth >= 1440 ? "100%" : 1440}
                    // width={!check ? "100%" : 1432}
                >      
                <HeaderFilter visible groupInterval={10000} />   
                    {/* <FilterRow visible={true} /> */}
                    <FilterPanel visible={windowSize?.innerWidth >= 769 ? true : false} />
                    {/* <GroupPanel visible={true} /> */}
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    {/* <Grouping autoExpandAll={false} /> */}
                    <Column dataField="QueryID" caption="Query ID" allowHeade                           
                    allowHeaderFiltering={false}
                    />
                    <Column
                        dataField="Department"
                        caption="Department"
                        hidingPriority={windowSize.innerWidth <= 768 && 5}
                        dataType="string"
                        format="currency"
                        alignment="left"

                   /> 
                   
                    <Column
                        dataField="Status"
                        caption="Status"
                        hidingPriority={windowSize.innerWidth <= 768 && 4}
                        dataType="string"
                        format="currency"
                        alignment="left"
                        
                    />
                    {/* <Column
                        dataField="Date"
                        caption="Date"
                        dataType="date"
                        format="currency"
                        alignment="left"
                        cellRender={(e) => viewDate(e.data.Date)}

                    /> */}
                    <Column
                        dataField="Time"
                        caption="Time"
                        hidingPriority={windowSize.innerWidth <= 768 && 3}
                        dataType="time"
                        format="currency"
                        alignment="left"
                        allowHeaderFiltering={false}
                        
                    />
                    <Column
                        dataField="Type"
                        caption="Type"
                        hidingPriority={windowSize.innerWidth <= 768 && 2}
                        dataType="string"
                        format="currency"       
                        alignment="left"
                        
                    ></Column>
                    <Column
                        dataField="RaisedBy"
                        caption="Raised By"
                        hidingPriority={windowSize.innerWidth <= 768 && 1}
                        dataType="string"
                        format="currency"
                        alignment="left"
                    />
                    <Column
                        caption="Action"
                        dataField="Action"
                        hidingPriority={windowSize.innerWidth <= 768 && 0}
                        dataType="string"
                        allowSorting={false}
                        format="currency"
                        alignment="center"
                        rowAlternationEnabled={false}
                        allowHeaderFiltering={false}
                        cellRender={(e) => ViewDetails(e.data.QueryID)}
                    />
                    <Pager allowedPageSizes={'10'} showPageSizeSelector={false} />
                    <Paging defaultPageSize={10} />
                </DataGrid>)}
            </div>
            <div className='m-3 mr-0'>
                {/* <Paginate pageChange={pageChange} length={QueriesData.length}/>  */}
            </div>
            <div className='' >
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={ModalStyle}  className="md:p-4 p-2" >
                        <Typography id="modal-modal-title" variant="h6" component="h2" className="flex justify-between align-middle items-center">
                            <span className='lg:text-[18px] md:text-[18px] text-[15px]'>Query Details <br />

                                <b className='lg:text-[16px] md:text-[16px] text-[14px]'>  Query ID : {selectedId}</b></span>
                            <span className='p-4 border rounded-lg text-[#000] cursor-pointer  '
                                onClick={() => handleClose()}
                            >&times;</span>
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }} className='max-h-[60vh] overflow-auto '>
                            <QueryDetails detailsList={detailsList} windowSize={windowSize.innerWidth}/>
                        </Typography>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}
export default MyQueryTable


