import React from 'react';
const Esign = () => {
    return (
        <section className='bg-white 2xl:px-10 xl:px-10 lg:px-10 md:px-10 sm:px-10 px-3'>
            <div className='container flex flex-wrap justify-between items-center mx-auto my-6' >
                <div className='box mt-0 md:mt-5 px-4 md:px-20 pt-0 '>
                    <div className='title-box text-center'>
                        <h2 className='title text-3xl font-[600]  pb-3 text-[#0A2A4A]'>E-Sign [Digitally Sign Your Documents]</h2>
                    </div>
                    <div className="desc-box prose max-w-none mt-4 pb-8 dark:prose-dark xl:col-span-2">
                        <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 ">
                            <div className="sm:text-center lg:text-left">
                                <p className='text-left text-[#808D9A] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] text-[16px] 2xl:px-[5rem] xl:px-[5rem] lg:px-[5rem] md:px-[5rem] sm:px-[5rem] px-[0.5rem]  pt-4 font-medium'>
                                    An electronic signature, or e-signature, is data that is logically associated with other data and which is used by the signatory to sign the associated data. This type of signature has the same legal standing as a handwritten signature as long as it adheres to the requirements of the specific regulation (Information Technology Act (2000)                    </p>
                            </div>
                            <img
                                src="/assets/img/e-sign.png"
                                alt="banner-img"
                                className="w-[80%]"
                            />
                        </div>
                        <p className='text-left text-[#808D9A] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] text-[16px] 2xl:px-[5rem] xl:px-[5rem] lg:px-[5rem] md:px-[5rem] sm:px-[5rem] px-[0.5rem] font-medium'>
                            Under which it was created. It is now legally possible to forward your contract notes and other documents to your clients / business associates without printing them. You may even forward a signed document over e-mail and that is a valid proof in court of law in India. This is called digitally signing your documents. A digital signature is endorsed to the document that needs to be singed. The recipient can not only preserve and produce before various government authorities whenever required but also can verify that the document is properly signed or not.                    </p>

                        <p className='text-left text-[#808D9A] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] text-[16px] 2xl:px-[5rem] xl:px-[5rem] lg:px-[5rem] md:px-[5rem] sm:px-[5rem] px-[0.5rem] font-medium'>
                            When doing bulk mailing like sending few hundred contracts / bills on daily bases and having to print the documents, sign them, courier them is by itself a very painful job. The cost involved and the time lag in making these documents reach the client / recipient is also an important factor. Just because the document needs to be signed by a person, incurring such huge costs of handling documents apart from delayed information is something not advisable in the current environment.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Esign;