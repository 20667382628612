import React, { useState, useEffect } from "react";
import SliderCard from "./SliderCard";
import Slider from "react-slick";
import axios from "axios";
import fileDownload from "js-file-download";
import CrossPopUp from "./CrossPopUp";
import moment from "moment";

const BackArrow = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      class="back-forword-btn bg-white md:-m-7 -m-5 absolute z-50	 ext-blue-700 border border-blue-700 hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 left-[12%]"
    >
      <svg
        className="stroke-[#3491ff] hover:stroke-[#ffffff]  "
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};

const ForwardArrow = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      class="back-forword-btn  bg-white m-3 z-50 text-blue-700 border border-blue-700 hover:bg-[#3491FF]   hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute right-[4%]"
    >
      <svg
        className="stroke-[#3491ff] hover:stroke-[#ffffff]"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};
const CrossnetDownload = (props) => {
  const { dateData } = props;

  const [checkObj, setCheckObj] = useState({});
  const [model, setModal] = useState("");
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    nextArrow: <ForwardArrow />,
    prevArrow: <BackArrow />,
  };

  useEffect(() => {
    let Data = JSON.parse(localStorage.getItem("ProfileTab"));
    let check = Data?.["Cross"];
    setCheckObj(check);
  }, []);
  const DownloadZipFile = async (zippedFile, fileName = "ZippedData.zip") => {
    axios
      .get(zippedFile, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      });
    //   const blobPromise =  fetch(zippedFile)
    // .then(function (response) {
    //     if (response.status === 200 || response.status === 0) {
    //         return Promise.resolve(response.blob());
    //     } else {
    //         return Promise.reject(new Error(response.statusText));
    //     }
    // })

    //   let blob = new Blob([blobPromise], { type: 'application/zip' })

    //   const downloadUrl = URL.createObjectURL(blob)
    //   let a = document.createElement("a");
    //   a.href = downloadUrl;
    //   a.download = fileName;
    //   document.body.appendChild(a);
    //   a.click();
  };
  return (
    <>
      {checkObj.Cross === "Y" && (
        <div className=" test-back">
          <>
            <div className="py-10">
              <div className="">
                <div className="font-medium lg:text-3xl md:text-3xl mb-4	text-center text-[24px] text-[#0A2A4A]">
                  Cross & CrossNet
                </div>
                <div className="lg:text-[18px] mg:text-[18px] text-[16px] text-center text-[#808D9A] font-normal px-[1rem] md:px-[10rem] py-4">
                  Offers a lot more facilities and security than CDAS system.
                  Facilities includes, stationery management, signature
                  verification, current holding, etc.{" "}
                </div>
                <div className="lg:text-[18px] mg:text-[18px] text-[16px] text-center text-[#808D9A] font-normal px-8">
                  {props?.Result?.map((d, i) => {
                    return (
                      i == 1 && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: d?.result?.Data?.Message,
                          }}
                        ></div>
                      )
                    );
                  })}
                </div>
              </div>
              <div className="grid sm:grid-cols-2 md:grid-cols-4 justify-between mx-10 download-sliders">
                {![checkObj.Cross, checkObj.ESign].every((d) => d == "N") && (
                  <div className="">
                    <h2 className=" text-white text-lg text-center rounded-tl rounded-bl bg-[#3491FF] py-3 m-0 ">
                      {" "}
                      Head Office
                    </h2>
                    <div className="table-auto w-full  whitespace-no-wrap p-0 m-0  border-r-2">
                      <div className="bg-[#3491]  odd-color">
                        <div
                          className={`${checkObj?.Cross == "N" && "hidden"}`}
                        >
                          {/* <div>             <SliderCard title='Head office' subTitle='CrossX EXE' img={'/assets/img/svg-1.svg'} arrow={true} download={"/download/cross/cross.zip"} DownloadZipFile={props.DownloadZipFile} buttonTxt={checkObj?.Cross} clickToDownload />
                    </div> */}
                        </div>
                        <div
                          className={`${checkObj?.Cross == "N" && "hidden"}`}
                        >
                          <div>
                            <SliderCard
                              title="Head office"
                              subTitle="Cross"
                              date={
                                dateData.length > 0 &&
                                (dateData?.find((a) => a.Product == "Cross")
                                  ?.ReleasedOn ||
                                  "not")
                              }
                              img={"/assets/img/svg-1.svg"}
                              arrow={true}
                              // disabled={dateData.length > 0 &&( moment(dateData.find(a=> a[1] == "cross/cross.zip")[3]).isBefore('1999-12-31') }
                              download={"/download/cross/cross.zip"}
                              DownloadZipFile={props.DownloadZipFile}
                              buttonTxt={checkObj?.Cross}
                              clickToDownload
                            />
                          </div>
                        </div>
                        <div
                          className={`${checkObj?.Cross == "N" && "hidden"}`}
                        >
                          <div>
                            <SliderCard
                              title="Head office"
                              subTitle="DB Support"
                              date={
                                dateData.length > 0 &&
                                (dateData?.find(
                                  (a) => a.Product == "CrossDBSupport"
                                )?.ReleasedOn ||
                                  "not")
                              }
                              img={"/assets/img/svg-1.svg"}
                              arrow={true}
                              // disabled={dateData.length > 0 &&( moment(dateData.find(a=> a[1] == "cross/crossdb.zip")[3]).isBefore('1999-12-31') }

                              download={"/download/cross/crossdb.zip"}
                              DownloadZipFile={props.DownloadZipFile}
                              clickToDownload
                              buttonTxt={checkObj?.Cross}
                            />
                          </div>
                        </div>
                        <div
                          className={`${checkObj?.ESign == "N" && "hidden"}`}
                        >
                          <div>
                            <SliderCard
                              title="Head office"
                              subTitle="E-Sign"
                              date={
                                dateData.length > 0 &&
                                (dateData?.find(
                                  (a) => a.Product == "CrossESign"
                                )?.ReleasedOn ||
                                  "not")
                              }
                              // disabled={dateData.length > 0 &&( moment(dateData.find(a=> a[1] == "Esignpro/esignprocross.zip")[3]).isBefore('1999-12-31') }

                              img={"/assets/img/svg-1.svg"}
                              arrow={true}
                              download={"/download/Esignpro/esignprocross.zip"}
                              DownloadZipFile={props.DownloadZipFile}
                              clickToDownload
                              buttonTxt={checkObj?.ESign}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <SliderCard title='Head office' subTitle='CrossX EXE' img={'/assets/img/svg-1.svg'} arrow={true} index={props.index} download={"/downloads//cross.zip"} DownloadZipFile={props.DownloadZipFile} viewDetails={()=>{
                setModal("Head Office")
              }}/>
            <SliderCard title='Head office' subTitle='DB Support' img={'/assets/img/svg-1.svg'} arrow={true} index={props.index} download={"/downloads//crossdb.zip"} DownloadZipFile={props.DownloadZipFile} viewDetails={()=>{
                setModal("Head Office")
              }}/> */}
                  </div>
                )}
                {![checkObj.CrossNet].every((d) => d == "N") && (
                  <div className="">
                    <h2 className=" text-white text-lg text-center  bg-[#3491FF] py-3 m-0 ">
                      {" "}
                      Branch
                    </h2>
                    <div className="table-auto w-full  whitespace-no-wrap p-0 m-0  border-r-2">
                      <div className="bg-[#3491]  odd-color">
                        <div
                          className={`${checkObj?.CrossNet == "N" && "hidden"}`}
                        >
                          <div>
                            <SliderCard
                              title="Branch"
                              subTitle="CrossNet"
                              date={
                                dateData.length > 0 &&
                                (dateData?.find((a) => a.Product == "CrossNet")
                                  ?.ReleasedOn ||
                                  "not")
                              }
                              // disabled={dateData.length > 0 &&( moment(dateData.find(a=> a[1] == "crossnet/crossnet35.zip")[3]).isBefore('1999-12-31') }
                              img={"/assets/img/svg-1.svg"}
                              arrow={true}
                              download={"/download/crossnet/crossnet35.zip"}
                              DownloadZipFile={props.DownloadZipFile}
                              clickToDownload
                              buttonTxt={checkObj?.CrossNet}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <SliderCard title='Branch & Individual Client' subTitle='Framework' img={'/assets/img/svg-7.svg'} arrow={false} index={props.index} download={''} DownloadZipFile={props.DownloadZipFile} viewDetails={()=>{
                setModal("Branch & Individual Client")
              }}/>
            <SliderCard title='Branch & Individual Client' subTitle='Framework' img={'/assets/img/svg-7.svg'} arrow={false} index={props.index} download={''} DownloadZipFile={props.DownloadZipFile} viewDetails={()=>{
                setModal("Branch & Individual Client")
              }}/> */}
                  </div>
                )}
                {checkObj?.Website == "Y" && (
                  <div className="relative">
                    <h2 className=" text-white text-lg text-center  bg-[#3491FF] py-3 m-0 ">
                      Individual Client
                    </h2>
                    <div className="table-auto w-full  whitespace-no-wrap p-0 m-0  border-l-2">
                      <div className="bg-[#3491]  odd-color">
                        <div
                          className={`${checkObj?.Website == "N" && "hidden"}`}
                        >
                          <div>
                            <SliderCard
                              title="Individual Client"
                              // disabled={dateData.length > 0 &&( moment(dateData.find(a=> a[1] == "misc/Tradeweb.zip")[3]).isBefore('1999-12-31') }
                              subTitle="CrossWeb"
                              date={
                                dateData.length > 0 &&
                                (dateData?.find((a) => a.Product == "CrossWeb")
                                  ?.ReleasedOn ||
                                  "not")
                              }
                              img={"/assets/img/svg-1.svg"}
                              arrow={true}
                              download={"/download/misc/Tradeweb.zip"}
                              DownloadZipFile={props.DownloadZipFile}
                              clickToDownload
                              buttonTxt={checkObj?.Website}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {checkObj.Cross == "Y" && (
                  <div className="relative">
                    <h2 className=" text-white text-lg text-center rounded-tr rounded-br bg-[#3491FF] py-3 m-0 ">
                      Knowledge Base
                    </h2>
                    <div className="table-auto w-full  whitespace-no-wrap p-0 m-0  border-l-2">
                      <div className="bg-[#3491]  odd-color">
                        <div
                          className={`${checkObj?.Cross == "N" && "hidden"}`}
                        >
                          <div>
                            {" "}
                            <SliderCard
                              title="Knowledge Base"
                              subTitle="Circulars"
                              img={"/assets/img/svg-6.svg"}
                              arrow={false}
                              download={""}
                              clickToDownload
                              section="C"
                              buttonTxt={checkObj?.Cross}
                              view
                            />
                          </div>
                        </div>
                        <div
                          className={`${checkObj?.Cross == "N" && "hidden"}`}
                        >
                          <div>
                            {" "}
                            <SliderCard
                              title="Knowledge Base"
                              subTitle="Help"
                              img={"/assets/img/svg-6.svg"}
                              arrow={false}
                              download={true}
                              dtitle={"cross"}
                              DownloadZipFile={props.DownloadZipFile}
                              clickToDownload
                              buttonTxt={checkObj?.Cross}
                              view
                            />
                          </div>
                        </div>
                        <div
                          className={`${checkObj?.Cross == "N" && "hidden"}`}
                        >
                          <div>
                            <SliderCard
                              title="Knowledge Base"
                              subTitle="Modifications"
                              img={"/assets/img/svg-6.svg"}
                              arrow={false}
                              section="C"
                              buttonTxt={checkObj?.Cross}
                              view
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default CrossnetDownload;
