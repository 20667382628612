import React, { useState } from "react";
import Slider from "react-slick";
import cross1 from "../../components/common/Images/cross1.png"
import cross2 from "../../components/common/Images/cross2.png"
import cross3 from "../../components/common/Images/cross3.png"
import cross4 from "../../components/common/Images/cross4.png"
import cross5 from "../../components/common/Images/cross5.png"
import { LazyLoadImage } from "react-lazy-load-image-component";



const Banners = () => {
    const [left, setLeft] = useState(0)
    const [right, setRight] = useState(1)

    const handleLeftIcon = () => {
        setLeft(left + 1)
        setRight(right - 1)
    }

    const handleRightIcon = () => {
        setLeft(left - 1)
        setRight(right + 1)

    }

    const BackArrow = (props) => {
        return (
          <button type="button" onClick={() => { props.onClick(); handleLeftIcon() }} className={`${(left < 5 && left < 0) ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -m-8  z-50 text-blue-700 border hover:bg-[#ffffff]  shadow-lg hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute left-[2%] top-[50%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-8 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 left-[2%] top-[50%]"}`}>
            <svg width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        )
      }
    
      const ForwardArrow = (props) => {
        return (
          <button type="button" onClick={() => { props.onClick(); handleRightIcon() }} className={`${right > 0 && right < 5 ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -m-8  z-50 text-blue-700 border hover:bg-[#ffffff]  shadow-lg hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute right-[2%] top-[50%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-8 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 right-[2%] top-[50%]"}`}>
            <svg className="" width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        )
      }

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: false,
        initialSlide: 0,
        nextArrow: <ForwardArrow />,
        prevArrow: <BackArrow />,
    };
    return (
        <div className="hero-banner  bg-[#F5FAFE]" >
            <div className='container flex flex-wrap items-center mt-0 mx-auto relative overflow-hidden'>
                <main className="px-5 md:py-10 md:px- ">
                    <div className="grid gap-8 grid-cols-1 lg:grid-cols-2 lg:pl-7 md:pl-7 pl-0 items-center">
                        <div className="text-center md:text-left lg:text-left ">
                            <h1 className="lg:text-3xl tracking-tight md:text-xl text-[25px]  font-Lexend text-[#0A2A4A] font-semibold md:leading-3 sm:mx-auto  lg:pb-5 md:pb-3 pb-0 lg:mx-0 mt-4 md:mt-0
                            ">
                                CROSS
                            </h1>
                            <p className="lg:text-2xl md:leading-4 tracking-tight font-Lexend text-[#0A2A4A] text-[20px] md:text-2xl font-medium  sm:mx-auto   lg:mx-0 leading-[2rem]
                            ">
                                CDSL Record Organiser & Support System
                            </p>
                            <p className="mt-3 lg:text-[18px] text-gray-500 sm:mt-5 text-[15px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0">
                                A complete software for managing all activities in Depository participant’s office. It starts with recording of a client’s (beneficiary or clearing member) account opening information in CROSS. This back office may be at same location as CDAS system or at a remote location networked a branch.                            </p>
                            {/* <p className="mt-3 text-[18px] text-gray-500 sm:mt-5 sm:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0">
                           
                            Transfer instruction may also be entered into CROSS, which offers lot more facilities and security than CDAS system like stationery management, signature verification, current holding, dues etc. This also includes maker / checker concept where each transaction is required to be authorized by persons at different levels depending upon the value and type of transaction.                            
                            </p>    */}
                        </div>
                        <div className="lg:ml-[10px] lg:w-[100%] md:ml-[10px] md:w-[100%]">
                            <LazyLoadImage
                                src='/assets/img/Rectangle-tp.svg'
                                alt="banner-img"
                                className=" absolute lg:h-auto md:h-auto h-[181px] lg:top-[0px] md:top-0 top-0 lg:left-[87px] md:left-[87px] left-5 " effect="blur"

                            />
                            <LazyLoadImage
                                src='/assets/img/Rectangle-botm.png'
                                alt="banner-img" effect="blur"

                                className="absolute lg:bottom-[-5rem]  md:bottom-[7rem] 
                                min-h-0 
                                h-[183px]
                              md:top-[25.6px]
                              top-[12rem]
                              right-[44px]
                                lg:w-[150px]
                                md:h-[300px] 
                                lg:h-[300px] 
                                lg:top-[26.6rem] 
                                lg:right-[127px]
                                md:right-[127px]
                                "
                            />
                            <Slider {...settings}>

                                <div>
                                    <div className="lg:mb-10 lg:mt-[3px] md:mb-10 md:mt-[3px] mt-0 mb-0">
                                        <p className="text-[#00C673] font-semibold  text-center flex justify-center lg:text-[25px] md:text-[25px] text-[20px] m-0  ">
                                            Instruction Entry</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>
                                    <LazyLoadImage
                                        src={cross1}
                                        effect="blur"
                                        alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[369px] md:h-[369px] h-[248px] object-contain"
                                    />
                                </div>


                                <div>
                                    <div className="mb-10 mt-[3px]">
                                        <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Client Master</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>                                     <LazyLoadImage
                                        src={cross2}
                                        effect="blur"
                                        alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[369px] md:h-[369px] h-[248px] object-contain"
                                    />
                                </div>
                                <div>
                                    <div className="mb-10 mt-[3px]">
                                        <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Demat Entry</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>                                      <LazyLoadImage
                                        src={cross3}
                                        effect="blur"
                                        alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[369px] md:h-[369px] h-[248px] object-contain"
                                    />
                                </div>
                                <div>
                                    <div className="mb-10 mt-[3px]">
                                        <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Security Master</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>                                      <LazyLoadImage
                                        src={cross4}
                                        effect="blur"
                                        alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[369px] md:h-[369px] h-[248px] object-contain"
                                    />
                                </div>
                                <div>
                                    <div className="mb-10 mt-[3px]">
                                        <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Statement of transaction</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>                                    <LazyLoadImage
                                        src={cross5}
                                        effect="blur" alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[369px] md:h-[369px] h-[248px] object-contain"
                                    />
                                </div>
                                
                            </Slider>


                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
export default Banners;