import React from "react";

function Charges() {
  return (
    <>
      <section className="text-gray-600 body-font py-7">
        <div className="title-box text-center px-5 ">
          <h2 className="title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-9  pb-0 text-[#0A2A4A] text-center">
            Charges
          </h2>
        </div>
        <div className="container lg:px-14 md:px-14 px-7 mx-auto flex flex-wrap">
          <div className="flex flex-wrap w-full">
            <div className=" md:pr-10 md:py-6">
              <div className="flex relative pb-6">
                <div className=" pl-3 pr-2 border-l-2 border-[#0A2A4A]">
                  <span className="leading-relaxed text-[#0A2A4A] text-xl">
                    01
                  </span>
                </div>
                <div className="flex-shrink-0 w-[2rem] h-[2rem] inline-flex items-center justify-center text-white relative z-10 ">
                  <svg
                    width="46"
                    height="50"
                    viewBox="0 0 46 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46 25L2.38106e-06 50L8.84615 25L4.56663e-06 -2.01072e-06L46 25Z"
                      fill="#3491FF"
                    />
                  </svg>
                </div>
                <div className=" pl-4">
                  <p className="leading-relaxed pt-1 lg:text-[18px] md:text-[18px] text-[15px] font-medium text-[#808D9A]">
                    Defining various scheme and assigning to clients.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-6">
                <div className=" pl-3 pr-2 border-l-2 border-[#0A2A4A]">
                  <span className="leading-relaxed text-[#0A2A4A] text-xl">
                    02
                  </span>
                </div>
                <div className="flex-shrink-0 w-[2rem] h-[2rem] inline-flex items-center justify-center text-white relative z-10 ">
                  <svg
                    width="46"
                    height="50"
                    viewBox="0 0 46 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46 25L2.38106e-06 50L8.84615 25L4.56663e-06 -2.01072e-06L46 25Z"
                      fill="#3491FF"
                    />
                  </svg>
                </div>
                <div className=" pl-4">
                  <p className="leading-relaxed pt-1 lg:text-[18px] md:text-[18px] text-[15px] font-medium text-[#808D9A]">
                    Redefining scheme for multiple clients by selection.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-6">
                <div className=" pl-3 pr-2 border-l-2 border-[#0A2A4A]">
                  <span className="leading-relaxed text-[#0A2A4A] text-xl">
                    03
                  </span>
                </div>
                <div className="flex-shrink-0 w-[2rem] h-[2rem] inline-flex items-center justify-center text-white relative z-10 ">
                  <svg
                    width="46"
                    height="50"
                    viewBox="0 0 46 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46 25L2.38106e-06 50L8.84615 25L4.56663e-06 -2.01072e-06L46 25Z"
                      fill="#3491FF"
                    />
                  </svg>
                </div>
                <div className=" pl-4">
                  <p className="leading-relaxed pt-1 lg:text-[18px] md:text-[18px] text-[15px] font-medium text-[#808D9A]">
                    Provision of charges on rejected entries, Demat request,
                    late payments, additional copies of statements , DIS book
                    issue etc.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-6">
                <div className=" pl-3 pr-2 border-l-2 border-[#0A2A4A]">
                  <span className="leading-relaxed text-[#0A2A4A] text-xl">
                    04
                  </span>
                </div>
                <div className="flex-shrink-0 w-[2rem] h-[2rem] inline-flex items-center justify-center text-white relative z-10 ">
                  <svg
                    width="46"
                    height="50"
                    viewBox="0 0 46 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46 25L2.38106e-06 50L8.84615 25L4.56663e-06 -2.01072e-06L46 25Z"
                      fill="#3491FF"
                    />
                  </svg>
                </div>
                <div className=" pl-4">
                  <p className="leading-relaxed pt-1 lg:text-[18px] md:text-[18px] text-[15px] font-medium text-[#808D9A]">
                    Transaction charges may have fixed, minimum, maximum,
                    percent of value, per quantity (for demat) etc.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-6">
                <div className=" pl-3 pr-2 border-l-2 border-[#0A2A4A]">
                  <span className="leading-relaxed text-[#0A2A4A] text-xl">
                    05
                  </span>
                </div>
                <div className="flex-shrink-0 w-[2rem] h-[2rem] inline-flex items-center justify-center text-white relative z-10 ">
                  <svg
                    width="46"
                    height="50"
                    viewBox="0 0 46 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46 25L2.38106e-06 50L8.84615 25L4.56663e-06 -2.01072e-06L46 25Z"
                      fill="#3491FF"
                    />
                  </svg>
                </div>
                <div className=" pl-4">
                  <p className="leading-relaxed pt-1 lg:text-[18px] md:text-[18px] text-[15px] font-medium text-[#808D9A]">
                    Provision for monthly/ quarterly/ annual account maintenance
                    charges.
                  </p>
                </div>
              </div>
              <div className="flex relative pb-0">
                <div className=" pl-3 pr-2 border-l-2 border-[#0A2A4A]">
                  <span className="leading-relaxed text-[#0A2A4A] text-xl">
                    06
                  </span>
                </div>
                <div className="flex-shrink-0 w-[2rem] h-[2rem] inline-flex items-center justify-center text-white relative z-10 ">
                  <svg
                    width="46"
                    height="50"
                    viewBox="0 0 46 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46 25L2.38106e-06 50L8.84615 25L4.56663e-06 -2.01072e-06L46 25Z"
                      fill="#3491FF"
                    />
                  </svg>
                </div>
                <div className=" pl-4">
                  <p className="leading-relaxed pt-1 lg:text-[18px] md:text-[18px] text-[15px] font-medium text-[#808D9A]">
                    Displaying transaction carrying out cost, cash collection
                    and printing receipts while entering instructions (based on
                    latest rate file).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Charges;
