import React from 'react';

const Brokrage = ()=>{
    const ListData = [
        {title:"Trade files can be updated with trades on a day-to-day basis immediately at end of trading sessions so that remote clients can get data as soon as possible. The exact rate after brokerage will be available to clients. Apart from daily trade, Mark to Mark Profit / Loss for the settlement to date can also be provided as additional information."},
        {title:"Daily confirmation of data can be made available in a summarized format and if a client so wishes, may click on a security to have a detailed transaction for that security or may opt for a detailed report and get full data with each and every trade."},
        {title:"Trades done under different segments like forward, odd lot, Rolling, Bonds, and Auction may be available in a single confirmation."},
        {title:"For that matter, trades executed on different exchanges may also be made available on a single confirmation."},
        {title:"As a matter of convenience and reduced data size, trade details of the last 1 month may only be made available."},
    
    ];
    return (
        <>
            <section className="text-gray-600 body-font ">
                <div className="container lg:px-12 md:px-12 px-6 py-6  ">
                    <h2 className='text-center text-2xl font-bold' >Trades</h2>
                    <div className=' rounded-xl h-30 mx-auto mb-10 mt-0  p-[1.5px]  w-full min-w-min'  >
                        <ul>
                            {ListData.map((single,index)=>(
                            <li key={index} className="mt-3 lg:text-[18px] md:text-[18px] text-[16px] text-gray-500 sm:mt-5   sm:mx-auto md:mt-5  lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4 min-w-full max-w-full">{single.title}</li>
                            ))}
                        </ul>
                    </div>
                </div>
          </section>
        </>
    )
}
export default Brokrage;