import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ChangePassword from "./ChangePassword";
import CommexDownload from "./CommexDownload";
import CrossnetDownload from "./CrossnetDownload";
import EsignDownload from "./EsignDownload";
import EstroNetDownload from "./EstroNetDownload";
import LicenceKey from "./LicenceKey";
import MyAccount from "./MyAccount";
import MyProfileForm from "./MyProfileForm";
import MyQuery from "./MyQuery";
import Password from "./Password";
import TradePluseDownload from "./TradePluseDownload";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import Tooltip from '@mui/material/Tooltip';
import useFileDownloader from "../../../hooks/useFileDownloader";
import Downloader from "./Downloader";
import { DateAndTime, Dateapi, GetAPI, Notificationapi } from "../../../hooks/Service";
import PmsDownload from "./PmsDownload";
import Notification from "./Notification";
import DemoNotification from "./DemoNotification";
import Marquee from "react-fast-marquee";
import { async } from "q";
import { Clear } from "@mui/icons-material";


const TabsAndPilles = () => {


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])
  // const dispatch                    = useDispatch();
  // const {downloadLoading}           = useSelector()
  // const downloadReduxLoading        = useSelector((state)=>state.loader.downloadLoading);

  const [check, setcheck] = useState(false);
  const [TabsButton, setTabsButton] = useState("Downloads");
  const [CardCompo, setCardCompo] = useState("");
  const [currentTab, setcurrentTab] = useState("change");
  const [flg, setflg] = useState(false);
  const [Result, setResult] = useState([]);

  const data = JSON.parse(localStorage.getItem("ProfileTab"))

  let result = localStorage.getItem("trade_plus_token");

  const Navigate = useNavigate();
  const [dateData, setDateData] = useState([])
  useEffect(() => {
    callfunc();
    getdate();
  }, []);


  
  // useEffect(() => {
  //     const interval = setInterval(async() => {
  //       if(dateData.length == 0){
  //         let data = await fetch('https://tplus.in/download/File.php');
  //         if(data.ok){
  //           let resp = await data.json()  ;
  //           setDateData(resp)
  //         }
          
  //       }}, 5000)
  //     return () => clearInterval(interval);
 
  // }, [dateData]);


  const getdate = async () => { 
    // let data = await fetch('https://tplus.in/download/File.php');
    // let data = await fetch('https://tplus.in/api/tplus/ReleaseDates');
    let data = await Dateapi()
    // let resp = [];
    if(data.status){
      let resp = data?.result?.Data;
      setDateData(resp)
    }
  }

  const callfunc = async () => {
    let UserData = await GetAPI("Tplus/Profile");
    localStorage.setItem("ProfileTab", JSON.stringify(UserData?.result));
    setflg(true);
  };

  function stripHtml(html)
  {
    if(!html) return "";

     html = html.toString().replace("&nbsp", '');
     let tmp = document.createElement("DIV");
     tmp.innerHTML = html;
  
    var text =tmp.textContent || tmp.innerText || "";
  
  
  
     return text
  }

  useEffect(() => {
    if (!result) {
      Navigate("/downloads");
    }
  }, [TabsButton]);

  useEffect(()=>{
    NotificationAlert();
  },[])

  const NotificationAlert = async () => {
    let Apipath = ["T", "C", "E"];

    let resData = Apipath.map((d) => {
      const result = Notificationapi(d);
      return result;
    });
    resData = await Promise.all(resData);
    // let newData = []
    // if(data?.TradePlus?.TradePlus == "Y"){
    //   newData.push(resData[0])
    // }
    // if(data?.Cross?.Cross == "Y"){
    //   newData.push(resData[1])
    // }
    // if(data?.Estro?.Estro == "Y"){
    //   newData.push(resData[2])
    // }
 setResult(resData);

    localStorage.setItem("Notification_data",JSON.stringify(resData))

    // let result = await Notificationapi(d);
  };

  const params = useParams();

  // const [downloadLoading,setDownloadLeading] = useState(false);

  const { files, downloadFile, remove } = useFileDownloader();

  const DownloadZipFile = (file) => {
    let obj = {
      name: file?.split("/")?.reverse()?.[0],
      file: file,
      filename: file?.split("/")?.reverse()?.[0],
    };
    if (file) {
      downloadFile(obj);
      // toast.success("File Downloaded", {
      //   progress: undefined,
      //   autoClose: 5000,
      // })
    } else {
      toast.warning("File Not Found", {
        progress: undefined,
        autoClose: 5000,
      });
    }

    // file ? downloadFile(obj):

    //   toast.error("invalid Password", {
    //     progress: undefined,
    //     autoClose: 5000,
    //   })
  };
  const Components = useMemo(()=>[
    {
      name: "Trade Plus X",
      components: (
        <TradePluseDownload index="0" DownloadZipFile={DownloadZipFile} Result={Result} dateData={dateData}/>
      ),
    },
    {
      name: "Estro & EstroNet",
      components: (
        <EstroNetDownload index="1" DownloadZipFile={DownloadZipFile} Result={Result} dateData={dateData}/>
      ),
    },
    {
      name: "Cross & CrossNet",
      components: (
        <CrossnetDownload index="0" DownloadZipFile={DownloadZipFile} Result={Result} dateData={dateData}/>
      ),
    },
    {
      name: "Commex",
      components: (
        <CommexDownload index="1" DownloadZipFile={DownloadZipFile} Result={Result} dateData={dateData}/>
      ),
    },
    // {
    //   name: "e-sign",
    //   components: <EsignDownload index="0" DownloadZipFile={DownloadZipFile} />,
    // },
    { name: "PMS", components: <PmsDownload index="1" DownloadZipFile={DownloadZipFile} dateData={dateData}/> },
  ],[dateData]);
  const tabs = [
    { name: "Downloads", components: <></> },
    { name: "My Profile",components: <MyProfileForm setcurrentTab={setcurrentTab} />, },
    { name: "My Account", components: <MyAccount /> },
    { name: "My Queries", components: <MyQuery setcheck={setcheck} /> },
    { name: "License Key", components: <LicenceKey /> },
    // { name: "Notification", components: <DemoNotification /> },
  ];
  useEffect(() => {
    setCardCompo(params.id);
  }, [params.id]);

  useEffect(() => {
    if (TabsButton == "License Key") {
      setcurrentTab(true)
      return
    }
    let pass = getCookie("password");
    let token = getCookie("token");
    pass ? setcurrentTab(true) : setcurrentTab("change");
    !token && Navigate("/downloads");
  }, [TabsButton]);

  const logout = () => {
    localStorage.removeItem("trade_plus_token")
    Navigate("/")
  }
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  return (
    <div>
      <div className="body-font">
        <div className="relative container xl:flex lg:flex justify-between items-center mx-auto ">
          {/* <div className="absolute flex text-center text-[20px] font-medium left-[33px] pl-2 text-[#3491FF]"> */}
            {/* {TabsButton} This i stesting  */}
            
          {/* </div> */}
          <div className="flex xl:w-[25%] lg:w-[25%] w-[100%] text-center md:text-[20px] text-[19px]  lg:text-[20px] font-medium left-[33px] 2xl:pl-8 xl:pl-8 lg:pl-8 md:pl-8 sm:pl-8 pl-3 text-[#3491FF] titlestyle">
              {(TabsButton !== 'My Profile' || TabsButton !== 'My Profile') && data?.ClientName}
            </div>
          <div className=" justify-between items-center  md:flex md:w-auto md:order-1 xl:mr-auto lg:mr-auto 2xl:mr-auto ">
            
            <div>
              <ul className="pagging flex text-[#9E9E9E]  md:m-8 m-4">
                {tabs.map((data) => {
                  return (
                    <li className="mr-2 tab-style">    
                      {data.name=='DemoNotification' ?     <DemoNotification/>:   <div
                        className={`flex justify-center tab-style inline-block py-3 px-4 rounded-lg cursor-pointer 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:[18px] text-[17px] ${TabsButton == data.name
                          ? "active bg-[#3491FF] text-white"
                          : "hover:text-gray-900 hover:bg-gray-100"
                          }`}
                        aria-current="page"
                        onClick={() => {
                          setTabsButton(data.name);
                        }}
                      >
                        {data.name}
                      </div>}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="inline-flex items-center bg-gray-100 border-0 py-1 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">
          </div>
        </div>
        <div className="lg:text-[18px] mg:text-[18px] text-[14px] text-center text-[#808D9A] font-normal px-8">
            {/* <Marquee>
            {
              Result?.map((d,i)=>{
                return (
                   <div className="text-red-500 " >{stripHtml( d?.result?.Data?.Message)}</div>
                )
              })
            }
            </Marquee> */}
            </div>
      </div>
      <div className="test-back">
        {flg ? (
          TabsButton == "Downloads" &&
          Components.map((data) => {
            return <>{data.components}</>;
          })
        ) : (
          <div role="status" className="loading-pannel">
            <svg
              aria-hidden="true"
              className="flex w-12 h-12 items-center justify-center text-gray-200 animate-spin fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
      {currentTab == "changepassword" ? (
        <ChangePassword setcurrentTab={setcurrentTab} />
      ) : currentTab == "changepasswordprofile" ? (
        <ChangePassword flag={"My Profile"} setcurrentTab={setcurrentTab} />
      ) : currentTab == "change" && TabsButton !== "Downloads" ? (
        <Password setcurrentTab={setcurrentTab} />
      ) : (
        <>
          {" "}
          {tabs.map((data) => {
            if (data.name === TabsButton) {
              return <div className="">{data.components}</div>;
            }
          })}
        </>
      )}
      {files.length ? (
        <div className=" fixed bg-slate-300  bottom-20 left-2  lg:w-[20%] md:w-[20%]  w-[80%] px-6 rounded-md z-[999999999999] ">
          <div className="lg:w-[100%] md:w-[100%] w-[80%] flex justify-end"></div>
          <Downloader files={files} remove={remove} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TabsAndPilles;
