import React, { useState } from "react";
import Slider from "react-slick";
import trade from "../../components/common/Images/Dashboard.png"
// const trade  = require("../../components/common/Images/Dashboard.png");
import Exchange from "../../components/common/Images/Exchange-Reporting.png"
import Client from "../../components/common/Images/Client-Onboarding.png"
import Bill from "../../components/common/Images/Bill-Processing.png"
import GrouOnime from "../../components/common/Images/ORC.png"
import { LazyLoadImage } from "react-lazy-load-image-component";



const Banner = () => {
    const [left, setLeft] = useState(0)
    const [right, setRight] = useState(1)


    const handleLeftIcon = () => {
        setLeft(left + 1)
        setRight(right - 1)
    }

    const handleRightIcon = () => {
        setLeft(left - 1)
    setRight(right + 1)

    }

    const BackArrow = (props) => {
        return (
          <button type="button" onClick={() => { props.onClick(); handleLeftIcon() }} className={`${(left < 5 && left < 0) ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -m-8  z-50 text-blue-700 border hover:bg-[#ffffff]  shadow-lg hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute left-[2%] top-[50%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-8 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 left-[2%] top-[50%]"}`}>
            <svg width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        )
      }
    
      const ForwardArrow = (props) => {
        return (
          <button type="button" onClick={() => { props.onClick(); handleRightIcon() }} className={`${right > 0 && right < 5 ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -m-8  z-50 text-blue-700 border hover:bg-[#ffffff]  shadow-lg hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute right-[2%] top-[50%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-8 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 right-[2%] top-[50%]"}`}>
            <svg className="" width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        )
      }

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        initialSlide: 0,
        nextArrow: <ForwardArrow />,
        prevArrow: <BackArrow />,
    };

    return (
        <div className="hero-banner  bg-[#F5FAFE]" >
            <div className='container flex flex-wrap  items-center   mx-auto px-12 relative overflow-hidden mt-0'>
                <main className="- ">
                    <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 my-10">
                        <div className=" lg:text-left md:text-left">
                            <h1 className=" text-center lg:text-left md:text-left text-3xl tracking-tight font-Lexend text-[#0A2A4A] font-semibold md:leading-3 sm:mx-auto  pb-5 lg:mx-0
                            ">
                                TradePlus
                            </h1>
                            <p className="text-center lg:text-left md:text-left lg:text-2xl lg:leading-4 tracking-tight font-Lexend text-[#0A2A4A] text-[20px] md:text-2xl font-medium  sm:mx-auto   lg:mx-0
                            ">
                                Back office package for Stock brokers.
                            </p>
                            <p className="text-center lg:text-left md:text-left lg:text-2xl leading-2 tracking-tight font-Lexend text-[#0A2A4A] text-[20px] md:text-2xl font-medium  sm:mx-auto   lg:mx-0
                            ">
                                Trades
                            </p>
                            {/* <p className="mt-3 text-[18px] text-gray-500 sm:mt-5 sm:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                                TradePlus supports import of all BSE and NSE files including BSE Auction file.
                            </p> */}
                            <p className="mt-3 lg:text-[18px] text-gray-500 sm:mt-5 text-[16px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                            100% compliant                            </p>
                            <p className="mt-3 lg:text-[18px] text-gray-500 sm:mt-5 text-[16px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                            Timely Regulatory Update                            </p>
                            <p className="mt-3 lg:text-[18px] text-gray-500 sm:mt-5 text-[16px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4 ">
                                Unique Trade Amendment screens that captures every element of trade execution.
                            </p>
                            <p className="mt-3 lg:text-[18px] text-gray-500 sm:mt-5 text-[16px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                                Provision to apply absolute brokerage on multiple trades on select and apply basis.                            </p>
                            <p className="mt-3 lg:text-[18px] text-gray-500 sm:mt-5 text-[16px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                                All processes required for Institutional Business.
                            </p>
                            <p className="mt-3 lg:text-[18px] text-gray-500 sm:mt-5 text-[16px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                                Automatic Effect for custodial trades.                            </p>
                            <p className="mt-3 lg:text-[18px] text-gray-500 sm:mt-5 text-[16px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                                Facility to accumulate trades and allocate quantity based on MF/PMS scheme details provided in .xls file.
                            </p>
                            <p className="mt-3 lg:text-[18px] text-gray-500 sm:mt-5 text-[16px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                                Generate files for most front office software for new client creation and limit setting, including additional limit file for BTST.
                            </p>
                        </div>

                        <div className="relative lg:ml-[40px] lg:w-[100%] ">
                            <img
                                src='/assets/img/Rectangle-tp.svg'
                                alt="banner-img"
                                className=" absolute top-[0px] lg:ml-[3rem] md:ml-[2rem] ml-[2rem] lg:w-[150px] w-[98px] md:w-[99px]"
                            />
                            <img
                                src='/assets/img/Rectangle-botm.png'
                                alt="banner-img"
                                className="absolute lg:bottom-[-5rem] lg:ml-[28rem] md:ml-[10.75rem]  md:bottom-[7rem] 
                                min-h-0 
                                h-[159px]
                                ml-[9rem]
                                lg:w-[150px]
                                md:h-[182px] 
                                lg:h-[350px] 
                                lg:top-[21.6rem]
                                top-[10.6rem] 
                                md:top-[13.6rem]
                                "
                            />
                            <Slider {...settings}>
                                <div>
                                    <div className=" mb-10 mt-[39px]">
                                        <p className="text-[#00C673] font-semibold  text-center flex justify-center lg:text-[25px] md:text-[25px] sm:text-[20px] m-0  ">
                                            Dashboard</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>
                                    <LazyLoadImage
                                        src={trade}
                                        alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[369px] object-contain flex" effect="blur"
                                    />
                                </div>
                                <div>
                                    <div className=" mb-10 mt-[39px]">
                                        <p className="text-[#00C673] lg:text-[25px] md:text-[25px] sm:text-[20px] font-semibold text-center flex justify-center  m-0 ">Exchange Reporting</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>                                    <LazyLoadImage effect="blur"
                                        src={Exchange}
                                        alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[369px] object-contain flex"
                                    />
                                </div>
                                <div>
                                    <div className=" mb-10 mt-[39px]">
                                        <p className="text-[#00C673] lg:text-[25px] md:text-[25px] sm:text-[20px] font-semibold text-center flex justify-center  m-0 ">Client Onboarding</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>                                    <LazyLoadImage effect="blur"
                                        src={Client}
                                        alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[369px] object-contain flex"
                                    />
                                </div>
                                <div>
                                    <div className=" mb-10 mt-[39px]">
                                        <p className="text-[#00C673] lg:text-[25px] md:text-[25px] sm:text-[20px] font-semibold text-center flex justify-center  m-0 ">Bill Processing</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>                                    <LazyLoadImage effect="blur"
                                        src={Bill}
                                        alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[369px] object-contain flex"
                                    />
                                </div>
                                <div>
                                    <div className=" mb-10 mt-[39px]">
                                        <p className="text-[#00C673] lg:text-[25px] md:text-[25px] sm:text-[20px] font-semibold text-center flex justify-center m-0 ">Margin Management</p>
                                        <hr class=" mx-auto w-[10%] lg:h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>    
                                    <div className="flex"><LazyLoadImage effect="blur"
                                        src={GrouOnime}
                                        alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[369px] object-contain flex"
                                    /></div>                                
                                </div>
                                {/* <img
  src="/assets/img/banner-img.svg"
  alt="banner-img"
  className="w-100"
/> */}
                            </Slider>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
export default Banner;