import React, { useState } from "react";
import BasicModal from "./ModelPopUp";
import uuid from "react-uuid";
// import useFileDownloader from "hooks/useFileDownloader";
// import useFileDownloader from "../../../hooks/useFileDownloader";

import { useSelector } from "react-redux";
import CircularAndMOdification from "./CircularAndMOdification";
import TradePlusPopUp from "./TradePlusPopUp";
import { useEffect } from "react";
import { PropaneRounded } from "@mui/icons-material";

const Card = (props) => {
  const { DownloadZipFile } = props;

  const downloadReduxLoading = useSelector(
    (state) => state.loader.downloadLoading
  );

  // const [downloadFile, downloaderComponentUI] = useFileDownloader();

  // const download = (file) => {
  //   let obj = {
  //     name: 'Info',
  //     file:  file,
  //     filename:'product-info.zip',
  //   }
  //   downloadFile(obj)
  // };

  const [model, setModel] = useState(false);
  const [secondModal, setsecondModal] = useState(false);
  const [tradeModel, setTradeModel] = useState(false);
  const sectiondId = uuid();

  /* 
    function str2bytes (str) {
      var bytes = new Uint8Array(str.length);
      for (var i=0; i<str.length; i++) {
          bytes[i] = str.charCodeAt(i);
      }
      return bytes;
  } */

  // useEffect(()=>{
  //   DownloadZipFile('Demo.zip',0);
  // },[])

  const LiveDownloadFile = async (dFile) => {
    if (dFile.includes("dotnet-hosting-3.1.4-win")) {
      window.open(
        "https://dotnet.microsoft.com/en-us/download/dotnet/thank-you/runtime-aspnetcore-3.1.4-windows-hosting-bundle-installer",
        null,
        "resizable=no,height=550,width=820,menubar=no,status=no,scrollbars=no,toolbar=no,left=190,top=100"
      );
      return;
    }
    // return;
    let dBURL = "https://tplus.in" + dFile;
    //  let a = fetch('https://tplus.in/download/File.php')
    const link = document.createElement("a");
    link.href = dBURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div class="text-gray-600 body-font relative">
      <div className="singled-slider">
        <div className=" flex justify-around items-center my-2">
          <div
            className={`${
              props.index == 1
                ? "flex xl:flex 2xl:flex lg:flex-wrap md:flex-wrap sm:flex-wrap flex-wrap flex-row justify-between p-1 px-[2px] items-center drop-shadow-md w-80 lg:w-80 md:w-80   width-class rounded-xl "
                : "flex xl:flex 2xl:flex lg:flex-wrap md:flex-wrap sm:flex-wrap flex-wrap flex-row justify-between p-1 px-[2px] items-center drop-shadow-md w-80 lg:w-80 md:w-80   width-class rounded-xl "
            }`}
          >
            <div className="main-card items-center justify-self-center flex-row 2xl:flex xl:flex lg:flex md:flex-wrap sm:flex-wrap flex-wrap pb-3">
              <img
                alt="No Image"
                src={props.img}
                className="img-card h-[2rem] w-[2rem] place-content-between block  pt-1 "
              />
              <div className="clear-space text-center lg:text-[11px] md:text-base 2xl:text-base xl:text-base sm:text-base text-base ml-2 text-[#0A2A4A] md:mt-0 lg:mt-[10px] mt-0">
                {props.subTitle}
                <span className="clear-space text-center font-semibold text-[14px] ml-2 text-blue-500">
                  {props.date == "not" ? "" : props.date}
                </span>
                <span className="clear-space text-center font-semibold text-[14px] ml-2 text-blue-500">
                  {props.time}
                </span>
              </div>
            </div>
            <button
              onClick={() => {
                // setTradeModel(true)
                if (props.viewDetails) {
                  props.viewDetails();
                  return;
                }
                if (
                  props.buttonTxt == "N" ||
                  props.buttonTxt == undefined ||
                  props.date == "not"
                ) {
                  return;
                }
                if (
                  props.subTitle === "Help" ||
                  (props.subTitle === "TradePlusX" && props.title === "TradeX")
                ) {
                  setModel(true);
                  return;
                }
                if (props.subTitle === "Circulars") {
                  setsecondModal(true);
                  return;
                }
                if (props.subTitle === "Modifications") {
                  setsecondModal(true);
                  return;
                } else {
                  // props.DownloadZipFile(props.download, sectiondId);
                  LiveDownloadFile(props.download);
                }
              }}
              type="button "
              className={`flex btn-card justify-center  items-center  uppercase text-size font-[8px] tracking-wid ${
                props.buttonTxt == "N" ||
                props.buttonTxt == undefined ||
                props.date == "not"
                  ? "bg-[#3491ff61]"
                  : "bg-[#3491FF]"
              } text-white p-1 rounded-lg focus:outline-none focus:shadow-outline `}
            >
              {downloadReduxLoading.id === sectiondId &&
              downloadReduxLoading.isCompleted === false ? (
                "Loading"
              ) : !props.clickToDownload || props.view ? (
                <svg
                  width="18"
                  height="18"
                  className="mx-2"
                  viewBox="0 0 101 69"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M50.5 0C82.0625 0 101 34.5 101 34.5C101 34.5 82.0625 69 50.5 69C18.9375 69 0 34.5 0 34.5C0 34.5 18.9375 0 50.5 0ZM50.5 6.27273C28.28 6.27273 12.6124 26.6654 7.41719 34.5C12.6061 42.3284 28.2737 62.7273 50.5 62.7273C72.72 62.7273 88.3876 42.3346 93.5828 34.5C88.3939 26.6716 72.7263 6.27273 50.5 6.27273V6.27273ZM50.5 12.5455C56.3596 12.5455 61.9793 14.8585 66.1226 18.9758C70.266 23.0931 72.5938 28.6773 72.5938 34.5C72.5938 40.3227 70.266 45.9069 66.1226 50.0242C61.9793 54.1415 56.3596 56.4545 50.5 56.4545C44.6404 56.4545 39.0207 54.1415 34.8774 50.0242C30.734 45.9069 28.4062 40.3227 28.4062 34.5C28.4062 28.6773 30.734 23.0931 34.8774 18.9758C39.0207 14.8585 44.6404 12.5455 50.5 12.5455V12.5455ZM50.5 18.8182C46.3161 18.8232 42.305 20.4769 39.3465 23.4168C36.388 26.3566 34.7238 30.3425 34.7188 34.5C34.7187 43.1438 41.7951 50.1818 50.5 50.1818C59.2049 50.1818 66.2812 43.1438 66.2812 34.5C66.2812 25.8562 59.2049 18.8182 50.5 18.8182Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <>
                  <svg
                    className="mx-2"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.1843 13.5135V17.8378C21.1843 19.0172 20.2015 20 19.0221 20H4.16216C2.9828 20 2 19.0172 2 17.8378V13.5135C2 13.199 2.27518 12.9238 2.58968 12.9238C2.90418 12.9238 3.17936 13.199 3.17936 13.5135V17.8378C3.17936 18.3882 3.61179 18.8206 4.16216 18.8206H19.0221C19.5725 18.8206 20.0049 18.3882 20.0049 17.8378V13.5135C20.0049 13.199 20.2801 12.9238 20.5946 12.9238C20.9091 12.9238 21.1843 13.1597 21.1843 13.5135ZM10.4519 15.3612C10.7664 15.6757 11.1988 15.8329 11.5919 15.8329C11.985 15.8329 12.4175 15.6757 12.732 15.3612L15.4838 12.6093C15.7197 12.3735 15.7197 12.0197 15.4838 11.7838C15.2479 11.5479 14.8941 11.5479 14.6583 11.7838L12.1816 14.2604V4.58968C12.1816 4.27518 11.9064 4 11.5919 4C11.2774 4 11.0022 4.27518 11.0022 4.58968V14.2214L8.52557 11.7447C8.2897 11.5088 7.93589 11.5088 7.70002 11.7447C7.46415 11.9806 7.46415 12.3344 7.70002 12.5702L10.4519 15.3612Z"
                      fill="white"
                      stroke="white"
                      stroke-width="0.5"
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
        </div>
        {/* {props.subTitle == "TradePlusX EXE" ?<small className="w-[100%] flex justify-center text-blue-500 absolute top-[14px] ml-[60px] text-[10px]">28/11/2022</small> : ""} */}
        {props.subTitle == "TradeWebX" || props.subTitle == "TradeWeb API" ? (
          <small className="w-[100%] flex justify-center text-red-500 absolute lg:top-[40px] md:top-[40px] top-[59px] ml-[12px] text-[10px] download-msg">
            Available For Testing Email us at info@Tplus.in
          </small>
        ) : (
          ""
        )}
        {props.subTitle == "TradeNet" ? (
          <small className="w-[100%] flex justify-center text-red-500 absolute lg:top-[40px] md:top-[40px] top-[59px] ml-[12px] text-[10px] download-msg">
            Discontinued
          </small>
        ) : (
          ""
        )}
        {/* {props.subTitle == "EstroNet" || props.subTitle == "Estro Web" ?<small className="w-[100%] flex justify-center text-red-500 absolute top-[34px] ml-[12px] text-[10px]">Available For Testing Email us at info@Tplus.in</small> : ""} */}
      </div>
      <BasicModal
        dtitle={props.dtitle}
        openModal={model}
        DownloadZipFile={LiveDownloadFile}
        sectiondId={sectiondId}
        onclose={() => {
          setModel(false);
        }}
      />
      <CircularAndMOdification
        openModal={secondModal}
        onclose={() => {
          setsecondModal(false);
        }}
        maintitle={props.subTitle}
        subtitle={props.section}
        DownloadZipFile={props.DownloadZipFile}
        // MyDownloadFN={props.DownloadZipFile}
      />
      {/* <TradePlusPopUp openModal={tradeModel} onclose={() => {setTradeModel(false)}} /> */}
    </div>
  );
};
const SliderCard = (props) => {
  // let fileInfo = getFileInfo(props.download);
  return (
    <Card
      buttonTxt={props.buttonTxt}
      cardtitle={props.cardtitle}
      subTitle={props.subTitle}
      date={props.date}
      time={props.time}
      index={props.index}
      img={props.img}
      arrow={true}
      download={props.download}
      DownloadZipFile={props.DownloadZipFile}
      dtitle={props.dtitle}
      section={props.section}
      viewDetails={props.viewDetails}
      clickToDownload={props.clickToDownload}
      view={props.view}
      title={props.title}
      disabled={props.disabled}
      // fileInfo={fileInfo}
    />
  );
};
const ProfileCard = (props) => {
  const downloadReduxLoading = useSelector(
    (state) => state.loader.downloadLoading
  );
  const sectiondId = uuid();
  return (
    <div className="singled-slider px-5 py-0">
      <div className=" flex flex-wrap justify-around items-center my-6">
        <div
          className={`${
            props.index == 1
              ? "flex justify-center p-2  bg-white md:p-5 md:bg-[#f4faff] items-center drop-shadow-md w-80 width-class rounded-xl flex-col"
              : "flex justify-center p-2  bg-white md:p-5 md:bg-[#FFFFFF] items-center drop-shadow-md w-80  width-class rounded-xl flex-col"
          }`}
        >
          <div className="items-center justify-self-center">
            <img
              alt="No Image"
              src={props.img}
              className="h-[4rem] w-[4rem] mb-3 place-content-between block absolute top-[3rem] left-1/2  -translate-x-1/2 -translate-y-1/2"
            />
            <h2 className="text-center text-xl mb-2 mt-[4.5rem] font-medium text-[#0A2A4A]">
              {props.subTitle}
            </h2>
          </div>
          <button
            onClick={() => {
              if (props.buttonTxt == "N" || props.buttonTxt == undefined) {
                return;
              }
              props.DownloadZipFile(props.download, sectiondId);
            }}
            type="button "
            className={`w-full justify-center flex items-center  uppercase text-size font-[10px] tracking-wid ${
              props.viewDetails
                ? "bg-[#3491FF]"
                : props.buttonTxt == "N" || props.buttonTxt == undefined
                ? "bg-[#3491ff61]"
                : "bg-[#3491FF]"
            } text-white p-3 rounded-lg focus:outline-none focus:shadow-outline mt-5	`}
          >
            {downloadReduxLoading.id === sectiondId &&
            downloadReduxLoading.isCompleted === false ? (
              "Loading"
            ) : !props.clickToDownload ? (
              <div className="flex items-center">
                <span className="mr-2">View Details</span>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 101 69"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M50.5 0C82.0625 0 101 34.5 101 34.5C101 34.5 82.0625 69 50.5 69C18.9375 69 0 34.5 0 34.5C0 34.5 18.9375 0 50.5 0ZM50.5 6.27273C28.28 6.27273 12.6124 26.6654 7.41719 34.5C12.6061 42.3284 28.2737 62.7273 50.5 62.7273C72.72 62.7273 88.3876 42.3346 93.5828 34.5C88.3939 26.6716 72.7263 6.27273 50.5 6.27273V6.27273ZM50.5 12.5455C56.3596 12.5455 61.9793 14.8585 66.1226 18.9758C70.266 23.0931 72.5938 28.6773 72.5938 34.5C72.5938 40.3227 70.266 45.9069 66.1226 50.0242C61.9793 54.1415 56.3596 56.4545 50.5 56.4545C44.6404 56.4545 39.0207 54.1415 34.8774 50.0242C30.734 45.9069 28.4062 40.3227 28.4062 34.5C28.4062 28.6773 30.734 23.0931 34.8774 18.9758C39.0207 14.8585 44.6404 12.5455 50.5 12.5455V12.5455ZM50.5 18.8182C46.3161 18.8232 42.305 20.4769 39.3465 23.4168C36.388 26.3566 34.7238 30.3425 34.7188 34.5C34.7187 43.1438 41.7951 50.1818 50.5 50.1818C59.2049 50.1818 66.2812 43.1438 66.2812 34.5C66.2812 25.8562 59.2049 18.8182 50.5 18.8182Z"
                    fill="white"
                  />
                </svg>
              </div>
            ) : (
              <>
                {" "}
                <span>Click To Download</span>
                <svg
                  className="mx-2"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.1843 13.5135V17.8378C21.1843 19.0172 20.2015 20 19.0221 20H4.16216C2.9828 20 2 19.0172 2 17.8378V13.5135C2 13.199 2.27518 12.9238 2.58968 12.9238C2.90418 12.9238 3.17936 13.199 3.17936 13.5135V17.8378C3.17936 18.3882 3.61179 18.8206 4.16216 18.8206H19.0221C19.5725 18.8206 20.0049 18.3882 20.0049 17.8378V13.5135C20.0049 13.199 20.2801 12.9238 20.5946 12.9238C20.9091 12.9238 21.1843 13.1597 21.1843 13.5135ZM10.4519 15.3612C10.7664 15.6757 11.1988 15.8329 11.5919 15.8329C11.985 15.8329 12.4175 15.6757 12.732 15.3612L15.4838 12.6093C15.7197 12.3735 15.7197 12.0197 15.4838 11.7838C15.2479 11.5479 14.8941 11.5479 14.6583 11.7838L12.1816 14.2604V4.58968C12.1816 4.27518 11.9064 4 11.5919 4C11.2774 4 11.0022 4.27518 11.0022 4.58968V14.2214L8.52557 11.7447C8.2897 11.5088 7.93589 11.5088 7.70002 11.7447C7.46415 11.9806 7.46415 12.3344 7.70002 12.5702L10.4519 15.3612Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.5"
                  />
                </svg>{" "}
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export const ProfileSliderCard = (props) => {
  return (
    <ProfileCard
      buttonTxt={props.buttonTxt}
      cardtitle={props.cardtitle}
      subTitle={props.subTitle}
      index={props.index}
      img={props.img}
      arrow={true}
      download={props.download}
      DownloadZipFile={props.DownloadZipFile}
      dtitle={props.dtitle}
      section={props.section}
      viewDetails={props.viewDetails}
      clickToDownload={props.clickToDownload}
    />
  );
};

export default SliderCard;
