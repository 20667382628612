import React, { useState } from "react";
import Slider from "react-slick";
import esign from "../../components/common/Images/e-sign.png"
import esign1 from "../../components/common/Images/e-sign1.png"
import trade from "../../components/common/Images/trade-plus.svg"
import Exchange from "../../components/common/Images/Exchange-Reporting.svg"
import Client from "../../components/common/Images/Client-Onboarding.svg"
import Bill from "../../components/common/Images/Bill-Processing.svg"
import Onime from "../../components/common/Images/Onime-Regulatory-Changes.svg"
import { LazyLoadImage } from "react-lazy-load-image-component";
const Banner = () => {
    const [left, setLeft] = useState(0)
    const [right, setRight] = useState(1)
    const handleLeftIcon = () => {
        setLeft(left + 1)
        setRight(right - 1)
    }

    const handleRightIcon = () => {
        setLeft(left - 1)
        setRight(right + 1)
    }
    const BackArrow = (props) => {
        return (
            <button type="button" onClick={() => { props.onClick(); handleLeftIcon() }} className={`${(left < 2 && left < 0) ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -m-8  z-50 text-blue-700  hover:bg-[#ffffff]  shadow-lg hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center absolute left-[-5%] lg:top-[50%] md:top-[50%] top-[57%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-8 absolute z-50 shadow-lg text-blue-700  hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center lg:top-[50%] md:top-[50%] top-[57%]"}`}>
                <svg width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        )
    }
    const ForwardArrow = (props) => {
        return (
            <button type="button" onClick={() => { props.onClick(); handleRightIcon() }} className={`${right > 0 && right < 2 ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -ml-5 z-50 text-blue-700  hover:bg-[#ffffff] shadow-lg  hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center absolute right-[-3%] top-[45%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn  bg-white -ml-5 z-50 text-blue-700 shadow-lg hover:bg-[#3491FF]   hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center absolute right-[-3%] top-[45%]"}`}>
                <svg className="" width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        )
    }
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        initialSlide: 0,
        nextArrow: <ForwardArrow />,
        prevArrow: <BackArrow />,
    };
    return (
        <div className="hero-banner  bg-[#F5FAFE]" >
            <div className='container flex flex-wrap justify-between items-center 2xl:px-10 xl:px-10 lg:px-10 md:px-10 sm:px-10 px-3  mx-auto relative overflow-hidden m-0'>
                <main className="m-0 md:py-10   px-4 md:px- ">
                    <div className="grid gap-8 grid-cols-1  lg:grid-cols-2 items-center  m-0">
                        <div className="text-center md:text-left lg:text-left">

                            <h1 className="text-3xl tracking-tight font-Lexend text-[#0A2A4A] font-semibold md:leading-3 lg:mx-auto  pb-5 lg:mx-0">
                                Associated modules
                            </h1>
                            <p className="mt-3 mb-8 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] text-gray-500 sm:mt-5 sm:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 ">
                                Digitally signed documents (contracts, bills, ledgers etc.)
                            </p>
                            {/* <h1 className="text-3xl tracking-tight font-Lexend text-[#0A2A4A] font-semibold md:leading-3 lg:mx-auto  pb-5 lg:mx-0
                            ">
                       E-Sign
                            </h1>
                            <p className="text-2xl leading-4 tracking-tight font-Lexend text-[#0A2A4A] sm:text-2xl md:text-2xl font-medium  sm:mx-auto   lg:mx-0">
                                Digitally Sign Your Documents                            
                            </p> */}
                            <p className="mt-1 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] text-gray-500 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 ">

                                Web site Browser based online branches Offline branches
                                with database and data transfer concept.
                            </p>
                            <p className="mt-3 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] text-gray-500 sm:mt-5 sm:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0 ">
                                Web site for Cash & Derivatives segment clubbed with DP
                                related information through single login.
                            </p>
                            {/* <p className="mt-3 text-[18px] text-gray-500 sm:mt-5 sm:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 ">
                            Offline Branches - Offline branches with database and data transfer concept.
                            </p> */}
                            <p className="mt-3 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] text-gray-500 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 ">
                                Printing of Combined quarterly reports of TradePlus and Depository services.
                                Includes TradePlus reports like ledger, delivery statements and Depository
                                reports like transaction cum holding statement.
                                Printing is done using laser printer with facility to print on both
                                side of paper (duplex printing), if supported by printer.
                                The facility results in huge cost saving as all statutory reports are sent
                                by a single courier and creates better impression with customers who
                                do not receive multiple couriers for same account.                            </p>
                            <p className="mt-3 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] text-[14px] text-gray-500 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 ">
                                Arbitrage related reports.                            </p>
                            {/* <p className="mt-3 text-[18px] text-gray-500 sm:mt-5 sm:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 ">
                            PMS                        </p> */}

                        </div>

                        <div className="2xl:ml-[40px] xl:ml-[40px] lg:ml-[40px] md:ml-[40px] sm:ml-[40px] ml-[20px] w-[100%]">
                            {/* <img
                                src='/assets/img/Rectangle-tp.svg'
                                alt="banner-img"
                                className=" absolute top-[0px] ml-[9rem]"
                            />
                            <img
                                src='/assets/img/Rectangle-botm.png'
                                alt="banner-img"
                                className="absolute lg:bottom-[-5rem] lg:ml-[28rem] md:bottom-[7rem] 
                                min-h-0 
                                h-[350px]
                                md:ml-3 
                                lg:w-[150px]
                                md:h-[300px] 
                                lg:h-[350px] 
                                top-[21.6rem] 
                                "
                            /> */}
                            <Slider {...settings}>
                                <div>
                                    <div className="mb-10 mt-[6px]">
                                        <p className="text-[#00C673] font-semibold  text-center flex justify-center lg:text-[25px] md:text-[25px] text-[20px] m-0  ">
                                        E-Sign</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>
                                    <LazyLoadImage effect="blur"
                                        src={esign}
                                        alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[550px] md:h-[550px] object-contain" 
                                    />
                                </div>
                                <div>
                                    <div className="mb-10 mt-[6px]">
                                        <p className="text-[#00C673] font-semibold text-center flex justify-center lg:text-[25px] md:text-[25px] text-[20px] m-0 ">Arbitrage Related Reports</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>      
                                    <   LazyLoadImage effect="blur"
                                        src={esign1}
                                        alt="banner-img"
                                        className="w-[100%] p-4 lg:h-[550px] md:h-[550px] object-contain" 
                                    />
                                </div>

                                {/* <div>
                                <div className="mb-10 mt-[6px]">
                                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Client Onboarding</p>
                                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                </div>                                    <LazyLoadImage
                                        src={Client}
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain" effect="blur"
                                    />
                                </div>
                                <div>
                                <div className="mb-10 mt-[6px]">
                                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Bill Processing</p>
                                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                </div>                                    <LazyLoadImage
                                        src={Bill}
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain" effect="blur"
                                    />
                                </div>
                                <div>
                                <div className="mb-10 mt-[6px]">
                                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Ontime Regulatory Charges</p>
                                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                </div>                                    <LazyLoadImage
                                        src={Onime}
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain" effect="blur"
                                    />
                                </div>  */}
                            
                                {/* <img
                                src="/assets/img/banner-img.svg"
                                alt="banner-img"
                                className="w-100"
                                /> */}
                            </Slider>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
export default Banner;