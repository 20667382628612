import React from 'react';
const SegIMg  = require('./img/seg-img.png');

function Statements() {
    return (
        <>
            <section className="text-gray-600 body-font">
                <div className=' text-center'>
                    <h2 className='title text-lg lg:text-2xl font-bold sm:text-2xl md:text-2xl pt-8 pb-0 text-[#0A2A4A] text-center'>Statements</h2>
                </div>
                <div className="container px-10 pb-10  mx-auto flex flex-wrap">
                <div className="flex flex-wrap  w-full justify-center">
                        <div className="p-4 w-[100%] lg:w-1/2">
                        <div className='relative lg:w-full md:w-full lg:h-[15rem] md:h-[15rem] h-[10rem]'>
                            <img src={SegIMg} className="lg:absolute md:absolute inherit  md:h-[230px]  h-[130px] w-[100%]" />
                                <div className="absolute px-[2rem] xl:top-[28%] lg:top-[16%] md:top-[24%] top-[19%]">
                                    <p className="leading-relaxed text-[#808D9A] lg:text-[18px] md:text-[18px] text-[13px] font-medium mb-0 text-center pt-4">Statement of account may be updated on daily basis and may also display Pay-in / Pay-out details from latest settlement along with scheduled pay-in / pay-out date.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 w-[100%] lg:w-1/2">
                        <div className='relative lg:w-full md:w-full lg:h-[15rem] md:h-[15rem] h-[10rem]'>
                            <img src={SegIMg} className="lg:absolute md:absolute inherit md:h-[230px] h-[130px] w-[100%]" />
                                <div className="absolute px-[2rem] xl:top-[28%] lg:top-[16%] md:top-[24%] top-[19%]">
                                    <p className="leading-relaxed text-[#808D9A] lg:text-[18px] md:text-[18px] text-[13px] font-medium mb-0 text-center pt-4">By referring to ledger, a remote client may at his end verify as to the remittance he has made is being reflected under his account or not.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Statements