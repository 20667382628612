import React from 'react';
const SegIMg = require('./img/seg-img.png');

function Statements({ windowSize }) {
    return (
        <>
            <section className="text-gray-600 body-font py-[35px]">
                <div className=' text-center'>
                    <h2 className='title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-3 pb-0 text-[#0A2A4A] text-center'>Statements</h2>
                </div>
                <div className="container 2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-2 pb-10  mx-auto lg:flex  lg:flex-wrap md:flex md:flex-wrap contents ">
                    <div className="2xl:flex xl:flex lg:flex flex-wrap  w-full">
                        <div className="p-4 lg:w-1/2 md:w-full sm:w-full">
                            <div className='relative lg:w-full md:w-full lg:h-[15rem] md:h-[15rem] h-[10rem]'>
                                <img src={SegIMg} className="lg:absolute md:absolute inherit lg:h-auto md:h-auto h-[147px]" />
                                <div className="absolute px-[2rem] xl:top-[32%] lg:top-[16%] md:top-[5%] top-[17%]">
                                    <p className="leading-relaxed text-[#808D9A] lg:text-[18px] md:text-[18px] text-[13px] font-medium text-center md:pt-6 sm:pt-2">Printing Holding statements, Transaction statements for clients at predefined intervals on Dot Matrix Printers / Page Printer.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 lg:w-1/2 md:w-full sm:w-full">
                            <div className='relative lg:w-full md:w-full lg:h-[15rem] md:h-[15rem] h-[10rem]'>
                                <img src={SegIMg} className="lg:absolute md:absolute inherit lg:h-auto md:h-auto h-[147px]" />
                                <div className="absolute px-[2rem] xl:top-[32%] lg:top-[16%] top-[24%]">
                                    <p className="leading-relaxed text-[#808D9A] lg:text-[18px] md:text-[18px] text-[13px] font-medium text-center md:pt-6 sm:pt-2">Automatic forwarding statements to client's e-mail address / Fax on predefined dates.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Statements