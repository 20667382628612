import React from "react";
const About = () => {
  return (
    <section className="bg-white px-7 ">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <div className="box mt-0 md:mt-5 lg:px-4 px-0 md:px-20  ">
          <div className="title-box text-center  ">
            <h2 className="title text-3xl font-[600]  pb-3 text-[#0A2A4A] pt-3  ">
              About
            </h2>
          </div>
          <div className="desc-box prose max-w-none mt-4 pb-8 dark:prose-dark xl:col-span-2">
            <p className="text-center text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] lg:px-[5rem]   md:px-[3px]   sm:px-[2px]  sm:py-[6px] font-medium">
            SecMark Consultancy Ltd is a listed public limited company established on 3rd August 2011. Our registered office is in Mumbai, Maharashtra. SecMark provides Financial Technology Solutions for all your compliance and Back-office support requirements for Financial Services.

We are a team of Industry experts with multiple professional qualifications and varied experience. we offer a wide range of services in terms of consulting, financial technology Solutions, outsourcing, Operational Risk Management solutions as well as business process solutions dedicated to the ultimate objective of customer satisfaction. As a result, more than 200 stock brokers, depository participants, and financial institutions which include MNCs, Large PSUs, and Leading Financial Players of the market have availed our services.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;
