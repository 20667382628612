import React, { useState } from "react";

const CommexForm = (props) => {
  const [Id, setId] = useState({
    MCX: "",
    NCDEX: "",
  });

  const storedata = (e) => {

    let Obj = {...Id}
    if(e.target.value[0]==="/"){
      Obj.clientCd = e.target.value
      Obj[e.target.name]=e.target.value
    }else{
    Obj[e.target.name]=e.target.value
    Obj.clientCd=""
    }


    setId({...Obj})
    if(Obj.clientCd) {
      Obj.mcxCMID = ''
      Obj.ncdexCMID = ''
    }
    props.setid(Obj )
  };

  return (
    <div>
      <form>
        <div className="mb-1">
          <label
            className="block text-[#0A2A4A] text-sm font-medium mb-2"
            for="username"
          >
            Clearing ID for MCX:
          </label>
          <input
            name="mcxCMID"
            className="shadow appearance-none border rounded 2xl:w-96 xl:w-96 lg:w-96 md:w-72 sm:w-60 w-[100%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Enter your Clearing ID for BSE"
            onChange={storedata}
            disabled={Id.ncdexCMID}

          />
        </div>
        {/* {props.check && (
          <div className="text-red-500 mx-2">Please Enter Details...</div>
        )} */}
        <div className="mb-4 mt-4">
          <label
            className="block text-[#0A2A4A] text-sm font-medium mb-2"
            for="username"
          >
            Clearing ID for NCDEX:
          </label>
          <input
            name="ncdexCMID"
            className="shadow appearance-none border rounded 2xl:w-96 xl:w-96 lg:w-96 md:w-72 sm:w-60 w-[100%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Enter your Clearing ID for BSE"
            onChange={storedata}
            disabled={Id.mcxCMID}
          />
        </div>
        {props.check&&<div className="text-red-500 mx-2 mb-2">Please Enter Atleast One Details...</div>}

      </form>

        {props.user && <div className='mb-3'>
          <lable className="text-red-500 mx-2">Please Enter Any One Field!!!!!</lable>
        </div>}
    </div>
  );
};

export default CommexForm;
