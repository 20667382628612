import React from "react";
const AG = require("./img/a-g.png");
const AB = require("./img/a-b.png");
const BGBlue = require("./img/bg-blue.png");
const BGGreen = require("./img/bg-green.png");
function OtherFeature() {
  return (
    <section className="bg-white ">
      <h2 className="title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-5  text-[#0A2A4A] relative text-center pb-5 ">
        Other Features
      </h2>
      <div className="container py-6 px-2 md:px-14 min-h-[30rem] mx-auto">
        <div className="lg:grid md:grid block grid-cols-2   justify-between min-h-[25rem]">
          <div className="bg-white lg:mb-0 md:mb-0 z-[10] relative    ">
            <img src={BGGreen} className="absolute z-[0] h-[22rem] lg:block md:block hidden" />
            <div className="content relative top-[2.5rem] left-[7rem] w-[65%]">
              <span className="text-[#00C673] text-[50px] font-[600]   ">
                01
              </span>
              <p className="text-[#808D9A] pt-4 lg:text-[18px] md:text-[18px] text-[16px] ">
                User Group Rights can be defined at micro level i.e. add, edit,
                delete, print, preview, etc.
              </p>
            </div>
          </div>
          <div className="bg-white lg:mb-0 md:mb-0 z-[10] relative">
            <img src={BGBlue} className="absolute z-[0] h-[20rem] lg:block md:block hidden" />
            <div className="content relative top-[2.5rem] left-[7rem] w-[65%]">
              <span className="text-[#52A1FF] text-[50px] font-[600]   ">
                02
              </span>
              <p className="text-[#808D9A] pt-4 lg:text-[18px] md:text-[18px] text-[16px]">
                Holiday master linked with all data entries, can prevent wrong
                entries.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:grid md:grid block grid-cols-2   justify-between min-h-[24rem]">
          <div className="bg-white lg:mb-0 md:mb-0 z-[10] relative    ">
            <img src={BGBlue} className="absolute z-[0] h-[20rem] lg:block md:block hidden" />
            <div className="content relative top-[2.5rem] left-[7rem] w-[65%]">
              <span className="text-[#52A1FF] text-[50px] font-[600]   ">
                03
              </span>
              <p className="mt-[20px] text-[#808D9A] pt-4 lg:text-[18px] md:text-[18px] text-[16px] ">
                In-built Backup facility removes hassle from very important
                tasks.
              </p>
            </div>
          </div>
          <div className="bg-white   z-[10] relative    ">
            <img src={BGGreen} className="absolute z-[0] h-[20rem] mb-[30px] lg:block md:block hidden" />
            <div className=" h-[17rem] scrollbar-hide overflow-auto content relative top-[2.5rem] left-[7rem] w-[65%]">
              <span className="text-[#00C673] text-[50px] font-[600]  ">
                04
              </span>
              <p className="text-[#808D9A] h-[9.2rem] overflow-auto lg:text-[18px] md:text-[18px] text-[16px]  " id="scroll">
              In built archival facility for keeping aside old / data not currently required. User can also copy / transfer various types of data for various periods accordingly he / she may decide range of data to be transferred that may be different for back office related data, front office related data, accounting data, masters etc. User also has choice to overlapping data in archival and live data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OtherFeature;
