import React, { useState, useEffect } from "react";
import BillingTable from "./BillingTable";
import Microsoftimg from "../../../components/common/Images/MicrosoftTeams-image (1).png";
import BillTable from "./BillTable";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import Dot from "../../../components/common/Images/Dot.svg";
import { Tooltip } from "antd";
import parse from "html-react-parser";

const AccountDescription = ({
  BillInfo,
  setinvoice,
  isDowbload = false,
  HandleClick,
  index,
  loadPanelVisible,
}) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "remove") {
        return <></>;
      }
    },
  };
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  let data = JSON.parse(localStorage.getItem("ProfileTab"));
  let is_instal = BillInfo?.Description?.includes("Annual Maintenance Charges");
  return (
    <>
      <div className="back-forword-btn 2xl:p-8 xl:p-8 lg:p-8 md:p-8 sm:p-8 p-2 mt-4 rounded-2xl	2xl:m-10 xl:m-10 lg:m-10 md:m-10 sm:m-10 m-3">
        <div className="flex justify-between">
          <div className="flex items-center">
            <div
              className="flex justify-end cursor-pointer mr-5"
              onClick={() => {
                setinvoice(false);
              }}
            >
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="50" height="50" rx="8" fill="#3491FF" />
                <path
                  d="M22.57 18.9297L16.5 24.9997L22.57 31.0697"
                  b
                  stroke="white"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M33.4999 25H16.6699"
                  stroke="white"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="mt-[20px]">
              <Tooltip placement="bottom" title={"Download-Invoice"}>
                <svg
                  className="w-6 flex items-center mt-[-20px] cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 122.88 120.89"
                  onClick={() =>
                    HandleClick(
                      BillInfo?.Year + "/" + BillInfo?.Bill,
                      "",
                      index,
                      BillInfo?.BillDate,
                      BillInfo.Bill
                    )
                  }
                >
                  <path d="M84.58,47a7.71,7.71,0,1,1,10.8,11L66.09,86.88a7.72,7.72,0,0,1-10.82,0L26.4,58.37a7.71,7.71,0,1,1,10.81-11L53.1,63.12l.16-55.47a7.72,7.72,0,0,1,15.43.13l-.15,55L84.58,47ZM0,113.48.1,83.3a7.72,7.72,0,1,1,15.43.14l-.07,22q46,.09,91.91,0l.07-22.12a7.72,7.72,0,1,1,15.44.14l-.1,30h-.09a7.71,7.71,0,0,1-7.64,7.36q-53.73.1-107.38,0A7.7,7.7,0,0,1,0,113.48Z" />
                </svg>
              </Tooltip>
            </div>
          </div>
          <div className="flex justify-between w-[100%]">
            <div className=" py-10 mx-auto d-block ">
              {BillInfo?.BillNo.includes("Proforma") && (
                <b className="text-2xl">Proforma Invoice</b>
              )}
            </div>
            <img src={Microsoftimg} className="w-[25%]" />
          </div>
        </div>

        <div className="flex justify-end w-[100%]">
          <div className="">
            <div className="text-[#3D3D3D] text-md flex justify-end">
              CIN No : <b>L67190MH2011PLC220404</b>
            </div>
            <div
              className={`${
                windowSize?.innerWidth >= 320
                  ? "text-[#3D3D3D] text-md flex justify-end ml-[56px]"
                  : "text-[#3D3D3D] text-md flex justify-end"
              }`}
            >
              36/227, RDP 10, Sector 6,Charkop,
            </div>
            <div className="text-[#3D3D3D] text-md flex justify-end">
              Kandivali (West),Mumbai - 400 067
            </div>
            {BillInfo?.Email && (
              <div className="text-[#3D3D3D] text-md flex justify-end">
                E-mail:{" "}
                <span className="font-bold ml-3">{BillInfo?.Email}</span>
              </div>
            )}
            {BillInfo?.URL && (
              <div className="text-[#3D3D3D] text-md flex justify-end">
                URL: <span className="font-bold ml-6"> {BillInfo?.URL}</span>
              </div>
            )}
          </div>
        </div>

        {windowSize?.innerWidth >= 390 ? (
          <div>
            <table className="w-[100%]">
              <tr>
                <td
                  className="border-2 border-black w-[60%]"
                  rowSpan={is_instal ? 3 : 4}
                >
                  <div>
                    <div className="p-[10px]">
                      <div className="font-bold text-lg text-[#3D3D3D]">
                        {BillInfo?.CompanyName}
                      </div>
                      <div className="text-[#3D3D3D] text-md">
                        {BillInfo?.Add1},
                      </div>
                      <div className="text-[#3D3D3D] text-md">
                        {BillInfo?.Add2},
                        <span className="text-[#3D3D3D] text-md">
                          {BillInfo?.City} - {BillInfo?.Pin}
                        </span>
                      </div>
                      <div className="text-[#3D3D3D] text-md">
                        {BillInfo?.Add3}
                      </div>
                      <div className="flex justify-start text-lg text-[#3D3D3D] p-[10px]">
                        Your GST ID:
                        <span className="mr-4 font-bold">
                          {BillInfo.YourGSTID}
                        </span>
                      </div>

                      {BillInfo?.BillRemark && (
                        <div className="flex justify-start text-lg text-[#3D3D3D] p-[10px]">
                          <span className="mr-4 font-bold">
                            {BillInfo?.BillRemark}
                          </span>
                        </div>
                      )}
                      {/* Certificate */}
                    </div>
                  </div>
                </td>
                <td className="border-2 border-black" colSpan={2}>
                  <div className="flex justify-center font-bold text-lg text-[#3D3D3D] p-[10px]">
                    {BillInfo?.BillNo}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-2 border-black">
                  <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                    Date :
                  </div>
                </td>
                <td className="border-2 border-black">
                  <div className="flex justify-end font-bold text-lg text-[#3D3D3D] p-[10px]">
                    {BillInfo?.BillDate}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border-2 border-black">
                  <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                    Due Date From :
                  </div>
                </td>
                <td className="border-2 border-black">
                  <div className="flex justify-end font-bold text-lg text-[#3D3D3D] p-[10px]">
                    {BillInfo?.FromDate}
                  </div>
                </td>
              </tr>
              {is_instal ? (
                ""
              ) : (
                <tr>
                  <td className="border-2 border-black">
                    <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                      Installation :
                    </div>
                  </td>
                  <td className="border-2 border-black">
                    <div className="flex justify-end font-bold text-lg text-[#3D3D3D] p-[10px]">
                      {BillInfo?.ToDate}
                    </div>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={2} className="border-2 border-black">
                  <div className="flex justify-start font-bold text-lg text-[#3D3D3D] p-[10px]">
                    Description
                  </div>
                </td>
                <td className="border-2 border-black">
                  <div className="flex justify-end font-bold text-lg text-[#3D3D3D] p-[10px]">
                    Rupees
                  </div>
                </td>
              </tr>

              <tr>
                <td colSpan={2} className="border-2 border-black">
                  <div>
                    <div
                      className="flex justify-start text-lg text-[#3D3D3D] p-[10px]"
                      dangerouslySetInnerHTML={{ __html: BillInfo.Description }}
                    ></div>
                  </div>
                </td>
                <td className="border-2 border-black">
                  <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                    {BillInfo.Amount}
                  </div>
                </td>
              </tr>

              {BillInfo?.SGST ? (
                <tr>
                  <td colSpan={2} className="border-2 border-black">
                    <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                      SGST Collected @ 9%
                    </div>
                  </td>
                  <td className="border-2 border-black">
                    <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                      {BillInfo.SGST}
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )}

              {BillInfo?.CGST ? (
                <tr>
                  <td colSpan={2} className="border-2 border-black">
                    <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                      CGST Collected @ 9%
                    </div>
                  </td>
                  <td className="border-2 border-black">
                    <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                      {BillInfo.CGST}
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )}

              {BillInfo?.IGST ? (
                <tr>
                  <td colSpan={2} className="border-2 border-black">
                    <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                      IGST Collected @ 18%
                    </div>
                  </td>
                  <td className="border-2 border-black">
                    <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                      {BillInfo.IGST}
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )}

              {BillInfo?.UGST ? (
                <tr>
                  <td colSpan={2} className="border-2 border-black">
                    <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                      UGST Collected @ 9%
                    </div>
                  </td>
                  <td className="border-2 border-black">
                    <div className="flex justify-end text-lg text-[#3D3D3D] p-[10px]">
                      {BillInfo.UGST}
                    </div>
                  </td>
                </tr>
              ) : (
                ""
              )}

              <tr>
                <td colSpan={2} className="border-2 border-black">
                  <div className="flex justify-start text-lg text-[#3D3D3D] p-[10px]">
                    {BillInfo.TotalInWords}
                  </div>
                </td>
                <td className="border-2 border-black">
                  <div className="flex justify-end font-bold text-lg text-[#3D3D3D] p-[10px]">
                    {BillInfo.Total}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        ) : (
          <BillTable BillInfo={BillInfo} />
        )}
        <div>
          <div>
            <div className="flex justify-between w-[99%] text-[#3D3D3D] text-md">
              <div>E&OE</div>

              <div></div>
              <div>{data?.ClientCode}</div>
            </div>
            {BillInfo.BillRemark?.length > 0 ? (
              <b>
                SUPPLY MEANT FOR EXPORT/SUPPLY TO SEZ UNIT OR SEZ DEVELOPER FOR
                AUTHORISED OPERATIONS UNDER BOND OR LETTER OF UNDERTAKING
                WITHOUT PAYMENT OF INTEGRATED TAX
              </b>
            ) : (
              ""
            )}
            <div className="py-1 text-[#3D3D3D]">
              <span className="font-bold">{BillInfo?.GSTText}</span>
            </div>
            <div className="font-bold text-md	text-[#3D3D3D]">
              {BillInfo?.CategoryText}
            </div>
            <div className="flex justify-between">
              <div>
                <ul>
                  <li className="py-1 text-[#3D3D3D] flex">
                    <img src={Dot} className="mr-2" alt="" />
                    <span>
                      This document is for intimation and verification only.
                    </span>
                  </li>
                  <li className="py-1 text-[#3D3D3D] flex">
                    <img src={Dot} className="mr-2" alt="" />
                    <span>
                      Actual bill will be prepared and forwarded on receipt of
                      Payment.*
                    </span>
                  </li>
                  <li className="py-1 text-[#3D3D3D]">
                    <div className="flex">
                      <img src={Dot} className="mr-2" alt="" />
                      Our Bank A/c Details: State Bank of India,Borivali West
                      Branch
                    </div>
                    {/* <br/> */}
                    <div className="ml-3"> IFSC Code: SBIN0000551</div>
                    <div className="ml-3"> Current A/c No.: 39744127423</div>
                  </li>
                  <li className="py-1 text-[#3D3D3D] flex">
                    <img src={Dot} className="mr-2" alt="" />
                    <span>
                      {/* {BillInfo?.PanNo} */}
                      Applicable TDS may be deducted{" "}
                      <span>
                        (PAN: <span className="font-bold">AAQCS1732G)</span>
                      </span>{" "}
                    </span>
                  </li>
                  <li className="py-1 text-[#3D3D3D] flex">
                    <img src={Dot} className="mr-2" alt="" />
                    <span>Subject to Mumbai Jurisdiction only.</span>
                  </li>
                  <li className="py-1 text-[#3D3D3D] flex">
                    <img src={Dot} className="mr-2" alt="" />
                    <span>
                      This being computer generated statement, not signed.
                    </span>
                  </li>
                </ul>
              </div>

              <div className={windowSize?.innerWidth <= 428 && ""}>
                <div className="font-bold py-6 text-[#3d3d3d] 2xl:text-lg xl:text-lg lg:text-lg md:text-lg sm:text-lg text-[16px]">
                  SECMARK CONSULTANCY LTD.
                </div>
              </div>
            </div>
            <div className="customHtml">
              {parse(`${BillInfo.Annexure}`, options)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDescription;
