import React from 'react'
import "./brokerage.scss";
const C1 = require('./img/c-1.png');
const C2 = require('./img/c-2.png');

function Brokerage() {
  return (
    <section id="commex-brokerage" className="text-gray-600 body-font ">
      <div className="container px-5 py-12 mx-auto ">
        <div className="flex flex-col text-center w-full lg:mt-[2rem] md:mt-[2rem] lg:mb-[3rem] md:mb-0">
          <h2 className="title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-0 pb-0 text-[#0A2A4A]">Brokerage Facility</h2>
        </div>
        <div className='main-grid grid-on-left  ' >
          <div className='grid-items' >
            <div className='relative '  >
              <div className="rounded-full w-[15rem] h-[15rem] mx-auto  mt-10 bg-gradient-to-r p-[12px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7] relative shadow">
                <div className=" flex-col  h-full bg-white text-white rounded-full p-4 flex justify-center items-center">
                  <p className='tetx-[400] lg:text-[18px] md:text-[18px] text-[16px] text-center text-[#808D9A]  '> For each slab a Minimum / Percent / Maximum / Fixed brokerage may be defined. </p>
                </div>
              </div>
            </div>
          </div>
          <div className='grid-items' >
            <div className='relative ' >
              <div className="rounded-full w-[15rem] h-[15rem] mx-auto  mt-10 bg-gradient-to-r p-[12px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7] relative shadow">
                <div className=" flex-col  h-full bg-white text-white rounded-full p-4 flex justify-center items-center">
                  <p className='tetx-[400] lg:text-[18px] md:text-[18px] text-[16px] text-center text-[#808D9A] '> Minimum Brokerage per contract may also be defined.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='grid-items' >
            <div className='relative' >
              <div className="rounded-full w-[15rem] h-[15rem] mx-auto  mt-10 bg-gradient-to-r p-[12px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7] relative shadow">
                <div className=" flex-col  h-full bg-white text-white rounded-full p-4 flex justify-center items-center">
                  <p className='tetx-[400] lg:text-[18px] md:text-[18px] text-[16px] text-center text-[#808D9A] '>   Speculation, squaring off brokerages can be defined for same client. </p>
                </div>
              </div>
            </div>
          </div>
          <div className='grid-items' >
            <div className='relative ' >
              <div className="rounded-full w-[15rem] h-[15rem] mx-auto  mt-10 bg-gradient-to-r p-[12px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7] relative shadow">
                <div className=" flex-col  h-full bg-white text-white rounded-full p-4 flex justify-center items-center">
                  <p className='tetx-[400] lg:text-[18px] md:text-[18px] text-[16px] text-center text-[#808D9A] '> Brokerage discounts may be offered based on daily turnover.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='grid-items' >
            <div className='relative '>
              <div className="rounded-full w-[15rem] h-[15rem] mx-auto  mt-10 bg-gradient-to-r p-[12px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7] relative shadow">
                <div className="flex flex-col  h-full bg-white text-white rounded-full p-4 justify-center items-center">
                  <p className='tetx-[400] lg:text-[18px] md:text-[18px] text-[16px] text-center text-[#808D9A] '> Introducing person’s brokerage may be credited to his accounts on settlement posting. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='clearfix' ></div>
        <div className=' md:hidden block grid-cols-5  lg:mt-[4rem] md:mt-[10rem] mt-0 lg:hidden   lg:desktop-grid  '>
          <div className='relative lg:left-[2rem] md:left-[2rem] left-0'>
            <div className="rounded-full w-[15rem] h-[15rem] mx-auto  mt-10 bg-gradient-to-r p-[12px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7] relative">
              <div className="flex flex-col  h-full bg-white text-white rounded-full lg:p-4 md:p-3 p-2 justify-center items-center">
                <p className='tetx-[400] lg:text-[18px] md:text-[18px] text-[16px] text-center text-[#808D9A]  '> For each slab a Minimum / Percent / Maximum / Fixed brokerage may be defined. </p>
              </div>
            </div>
            <img  className="w-full absolute bottom-[-14px] left-[-3%] min-w-[22rem] max-w-[30rem] z-[-10] " style={{ borderRadius: '98% 0% 0% 0%' }} />
          </div>
          <div className='relative lg:top-[-10rem] md:top-[-10rem] top-0'>
            <div className="rounded-full w-[15rem] h-[15rem] mx-auto  mt-10 bg-gradient-to-r p-[12px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7] relative">
              <div className="flex flex-col  h-full bg-white text-white rounded-full lg:p-4 md:p-3 p-2 justify-center items-center">
                <p className='tetx-[400] lg:text-[18px] md:text-[18px] text-[16px] text-center text-[#808D9A] '> Minimum Brokerage per contract may also be defined.
                </p>
              </div>
            </div>
            <img  className="w-full absolute top-[30px] left-[-29px] min-w-[22rem] max-w-[30rem] z-[-10]" />
          </div>
          <div className='relative' >
            <div className="rounded-full w-[15rem] h-[15rem] mx-auto  mt-10 bg-gradient-to-r p-[12px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7] relative">
              <div className="flex flex-col  h-full bg-white text-white rounded-full lg:p-4 md:p-3 p-2 justify-center items-center">
                <p className='tetx-[400] lg:text-[18px] md:text-[18px] text-[16px] text-center text-[#808D9A] '>   Speculation, squaring off brokerages can be defined for same client. </p>
              </div>
            </div>
            <img  className="w-full absolute bottom-[-14px] left-[-30px] min-w-[22rem] max-w-[30rem] z-[-10]" />
          </div>
          <div className='relative lg:top-[-10rem] md:top-[-10rem] top-0'>
            <div className="rounded-full w-[15rem] h-[15rem] mx-auto  mt-10 bg-gradient-to-r p-[12px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7] relative">
              <div className="flex flex-col  h-full bg-white text-white rounded-full lg:p-4 md:p-3 p-2 justify-center items-center">
                <p className='tetx-[400] lg:text-[18px] md:text-[18px] text-[16px] text-center text-[#808D9A] '> Brokerage discounts may be offered based on daily turnover.
                </p>
              </div>
            </div>
            <img  className="w-full absolute top-[30px] left-[-29px] min-w-[22rem] max-w-[30rem] z-[-10]" />
          </div>
          <div className='relative lg:right-[2rem] md:right-[2rem] right-0'>
            <div className="rounded-full w-[15rem] h-[15rem] mx-auto  mt-10 bg-gradient-to-r p-[12px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7] relative">
              <div className="flex flex-col  h-full bg-white text-white rounded-full lg:p-4 md:p-3 p-2 justify-center items-center">
                <p className='tetx-[400] lg:text-[18px] md:text-[18px] text-[16px] text-center text-[#808D9A] '> Introducing person’s brokerage may be credited to his accounts on settlement posting. </p>
              </div>
            </div>
            <img  className="w-full absolute bottom-[-14px] left-[-16%] min-w-[22rem] max-w-[30rem] z-[-10]" style={{ borderRadius: '0% 100% 0% 0%' }} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Brokerage