import React from "react";
import ImgIcon1 from "../ProductEstro/img/123.svg";
import ImgIcon2 from "../ProductEstro/img/1234.svg";
import ImgIcon3 from "../ProductEstro/img/12345.svg";
import ImgIcon4 from "../ProductEstro/img/123466.svg";

const BranchEstro = () => {
  return (
    <section className="brancher-sections  branch-timeline bg-[#F4FAFF]" id="cross-branch-section">
      <div className=" h-[75rem] w-full 2xl:px-12 xl:px-12 lg:px-12 md:px-12 sm:px-12 px-3 py-6">
        <div className="container mx-auto">
          <h2 className="title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl text-[#0A2A4A] relative text-center 2xl:pb-10 xl:pb-10 lg:pb-10 md:pb-10 sm:pb-10 pb-3 2xl:pt-10 xl:pt-10 lg:pt-10 md:pt-10 sm:pt-10 pt-3">
            Branches
          </h2>
          <hr className="pb-4 border-t-[#BEE1FB]" />
          <ol className="grid sm:grid-cols-4 branch-timeline-list ">
            <li>
              <div className="flex justify-center">
                <img src={ImgIcon1} className="w-[6rem] " />
              </div>
              <div className="txt-handler-bottom">
                <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px]  text-center">
                    Facility has been provided to create full functional branches that can operate without DPM systems and yet provide clients of branches with timely information and services.
                </p>
              </div>
            </li>
            <li>
              <div className="txt-handler-top">
                <div className="flex justify-center">
                    <img src={ImgIcon2} className="w-[6rem] rotate-180" />
                </div>
                <div className="txt-handler-bottom">
                <p className="text-[#808D9A] mt-2 lg:text-[18px] md:text-[18px] text-[16px]  text-center">
                    Reports on branch profitability and inter branch comparisons.
                </p>
                </div>
              </div>
            </li>
            <li>
              <div className="flex justify-center">
                <img src={ImgIcon3} className="w-[6rem] " />
              </div>
              <div className="txt-handler-bottom">
                <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px]  text-center">
                    Reports on earnings / expenses of branches for effective working of franchise concepts on branches.
                </p>
              </div>
            </li>
            <li>
              <div className="txt-handler-top">
                <div className="flex justify-center">
                    <img src={ImgIcon4} className="w-[6rem] rotate-180" />
                </div>
                <div className="txt-handler-bottom">
                <p className="text-[#808D9A] mt-2 lg:text-[18px] md:text-[18px] text-[16px]  text-center">
                    Transfer of data to and from branches using dial-up / web connectivity using Data transfer features of database to prevent handling errors and tampering.
                </p>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </div>
    </section>
  );
};

export default BranchEstro;
