import React, { useState } from "react";
import Slider from "react-slick";
import estro from "../../components/common/Images/estro.png"
import estro1 from "../../components/common/Images/estro1.png"
import estro2 from "../../components/common/Images/estro2.png"
import estro3 from "../../components/common/Images/estro3.png"
import estro4 from "../../components/common/Images/estro4.png"
import estro5 from "../../components/common/Images/estro5.png"
import { LazyLoadImage } from "react-lazy-load-image-component";
const Banners = () => {
  const [left, setLeft] = useState(0)
  const [right, setRight] = useState(1)


  const handleLeftIcon = () => {
    setLeft(left + 1)
    setRight(right - 1)
  }

  const handleRightIcon = () => {
    setLeft(left - 1)
    setRight(right + 1)

  }

  const BackArrow = (props) => {
    return (
      <button type="button" onClick={() => { props.onClick(); handleLeftIcon() }} className={`${(left < 6 && left < 0) ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -m-8  z-50 text-blue-700 border hover:bg-[#ffffff]  shadow-lg hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute left-[2%] top-[50%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-8 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 left-[2%] top-[50%]"}`}>
        <svg width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    )
  }

  const ForwardArrow = (props) => {
    return (
      <button type="button" onClick={() => { props.onClick(); handleRightIcon() }} className={`${right > 0 && right < 6 ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -m-8  z-50 text-blue-700 border hover:bg-[#ffffff]  shadow-lg hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute right-[2%] top-[50%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-8 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 right-[2%] top-[50%]"}`}>
        <svg className="" width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    )
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    initialSlide: 0,
    nextArrow: <ForwardArrow />,
    prevArrow: <BackArrow />,
  };

  return (
    <div className="hero-banner bg-[#F5FAFE]">
      <div className="container flex flex-wrap  items-center mx-auto relative overflow-hidden">
        <main className="px-4 py-8 md:py-10 md:px- ">
          <div className="grid gap-8 grid-cols-1 lg:grid-cols-2 my-10 2xl:pl-10 xl:pl-10 lg:pl-10 md:pl-10 sm:pl-10 pl-0">
            <div className="text-center lg:text-left md:text-left ">
              <h1 className="text-3xl tracking-tight pb-3 font-Lexend text-[#0A2A4A] font-semibold sm:mx-auto lg:mt-11 md:mt-10 mt-0   lg:mx-0
              ">
                ESTRO
              </h1>
              <p
                className="2xl:text-2xl xl:text-2xl lg:text-2xl text-[18px] leading-4 tracking-tight font-Lexend text-[#0A2A4A] sm:text-2xl md:text-2xl font-medium md:leading-8 sm:mt-5  sm:max-w-xl sm:mx-auto   lg:mx-0
              ">
                DP Back office Solution
              </p>
              <p
                className="lg:text-[18px] md:text-[18px] text-[16px] 2xl:leading-8 xl:leading-8 lg:leading-8 md:leading-8 sm:leading-8 tracking-tight font-Lexend text-[#0A2A4A] sm:text-xl md:text-xl font-medium sm:mt-5  sm:max-w-xl sm:mx-auto   lg:mx-0
              ">
                Back office / front office co-ordination, billing and accounting software for DPs with NSDL
              </p>
              <p className="mt-3 lg:text-[18px] md:text-[18px] text-[16px] font-normal text-gray-500 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 md:text-[17px] lg:mx-0">
                A complete software for managing all activities in Depository
                Participant’s office. It starts with recording of a client’s
                (beneficiary or clearing member) account opening information in
                Estro. This back office may be at same location as DPM system or
                at a remote location networked as branch.
              </p>
              {/* <p className="mt-3 text-[17px] font-normal text-gray-500 sm:mt-5 sm:text-[17px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[17px] lg:mx-0">
                Transfer instruction may also be entered into Estro, which
                offers lot more facilities and security than DPM system. This
                includes maker / checker concept as in use by banks where each
                transaction is required to be authorized by persons at different
                levels depending upon the value and type of transaction.
              </p> */}
            </div>
            <div className="lg:ml-[10px] md:ml-[10px] ml-0 w-[100%]">
              {/* <img
                src='/assets/img/Rectangle-tp.svg'
                alt="banner-img"
                className=" absolute top-[0px] ml-[9rem]"
              />
              <img
                src='/assets/img/Rectangle-botm.png'
                alt="banner-img"
                className="absolute lg:bottom-[-5rem] lg:ml-[28rem] md:bottom-[7rem] 
                                min-h-0 
                                h-[350px]
                                md:ml-3 
                                lg:w-[150px]
                                md:h-[300px] 
                                lg:h-[300px] 
                                top-[29.6rem] 
                                "
              /> */}
              <Slider {...settings}>
                <div>
                  <div className="mb-10 mt-[9px]">
                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Ledger</p>
                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                  </div>
                  <LazyLoadImage
                    src={estro1}
                    effect="blur"
                    alt="banner-img"
                    className="w-[100%] p-4 lg:h-[369px] md:h-[369px] h-[243px] object-contain"
                  />
                </div>
                <div>
                  <div className="mb-10 mt-[9px]">
                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Client Master</p>
                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                  </div>
                  <LazyLoadImage
                    src={estro2}
                    effect="blur"
                    alt="banner-img"
                    className="w-[100%] p-4 lg:h-[369px] md:h-[369px] h-[243px] object-contain"
                  />
                </div>
                <div>
                  <div className="mb-10 mt-[9px]">
                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Holding Print</p>
                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                  </div>
                  <LazyLoadImage
                    src={estro3}
                    effect="blur"
                    alt="banner-img"
                    className="w-[100%] p-4 lg:h-[369px] md:h-[369px] h-[243px] object-contain"
                  />
                </div>
                <div>
                  <div className="mb-10 mt-[9px]">
                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Statement of Transaction </p>
                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                  </div>
                  <LazyLoadImage
                    src={estro4}
                    effect="blur"
                    alt="banner-img"
                    className="w-[100%] p-4 lg:h-[369px] md:h-[369px] h-[243px] object-contain"
                  />
                </div>
                <div>
                  <div className="mb-10 mt-[9px]">
                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Bill Printing</p>
                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                  </div>
                  <LazyLoadImage
                    src={estro5} effect="blur"
                    alt="banner-img"
                    className="w-[100%] p-4 lg:h-[369px] md:h-[369px] h-[243px] object-contain"
                  />
                </div>
                <div>
                  <div className="mb-10 mt-[9px]">
                    <p className="text-[#00C673] font-semibold  text-center flex justify-center text-[25px] m-0  ">
                      Forwarding and Covering Letter</p>
                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                  </div>
                  <LazyLoadImage
                    src={estro} effect="blur"
                    alt="banner-img"
                    className="w-[100%] p-4 lg:h-[369px] md:h-[369px] h-[243px] object-contain"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default Banners;
