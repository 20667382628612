import React from 'react';

const HandleDetails = ({Details,MoreDetails,openIndex,srNo,title,DownloadZipFile,setOPenIndex,windowSize})=>{

// function HandleDetails({Details,MoreDetails,openIndex,srNo,title,DownloadZipFile}) {

  function stripHtml(html)
  {
    if(!html) return "";

     html = html.toString().replace("&nbsp", '');
     let tmp = document.createElement("DIV");
     tmp.innerHTML = html;
  
    var text =tmp.textContent || tmp.innerText || "";
  
    text = text.replace("nbsp;", '')
  
     return text
  }

    const [showMore,setShowMore] = React.useState(false);

    React.useEffect(()=>{
        if(openIndex===srNo){ setShowMore(true) }
    },[openIndex,srNo])

    const handleClick = ()=>{
      // let newt= title == "T" ? "tradplus" : title == "C" ? "cross" : title == "E" ? "estro" : title == "X" ? "commex" :"" 
          let url = `/Circlulars/Circular${srNo}.ZIP`;
          DownloadZipFile(url);
      // DownloadZipFile(url,'download.zip')
    }

  return (
    <div>
       <p onClick={()=> setOPenIndex(srNo == openIndex ? false : srNo)}>{stripHtml(Details)}
        
        
        {/* <button   className="btn text-blue-500 ml-2 cursor-pointer z-[10000]" onClick={()=>handleClick()} >Download</button>  */}
        </p>


        {(showMore)?<p dangerouslySetInnerHTML={{__html:MoreDetails}}>

        </p>:''}

        
      { windowSize <=420 && <button className='btn btn-info text-info text-blue-500'  onClick={()=>setShowMore(!showMore)}>
            {showMore?"Read Less":"Read More"}
        </button>}
    </div>
  )
}

export default HandleDetails