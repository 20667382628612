import React from 'react'
import Cross1 from '../../components/common/Images/c-1.png'
import Cross2 from '../../components/common/Images/c-2.png'
import Cross3 from '../../components/common/Images/c-3.png'
import Cross4 from '../../components/common/Images/c-4.png'


function Technology() {
    return (
        <>
            <section className="text-gray-600 body-font bg-[#F4FAFF]">
                <h2 className='title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-5 pb-0 text-[#0A2A4A] text-center'>Technology</h2>
                <div className="container px-5 py-12 mx-auto ">
                    <div className="flex flex-wrap -m-4 ">
                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                            <div className=" rounded-xl w-[18rem] h-[18rem] mx-auto bg-gradient-to-r pb-[6px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7]">
                                <div className="flex flex-col  h-[17.8rem] bg-white text-black  p-4 text-center">
                                    <div className='flex justify-center'>
                                        <img src={Cross1} className="w-[30%]"/>
                                    </div>
                                    <div className="mt-10">
                                        <h3 className="text-[#808D9A] font-normal lg:text-[17px] md:text-[17px] text-[16px] mb-6">State of art client / server architecture using RDBMS back end ensures fullest data security for substantially large database.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                            <div className=" rounded-xl w-[18rem] h-[18rem] mx-auto bg-gradient-to-r  pb-[6px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7]">
                                <div className="flex flex-col  h-[17.8rem] bg-white text-black  p-4">
                                <div className='flex justify-center'>
                                        <img src={Cross2} className="w-[30%]"/>
                                    </div>
                                    <div className="mt-10">
                                        <h3 className="text-[#808D9A] font-normal lg:text-[17px] md:text-[17px] text-[16px] mb-1 text-center">Intelligently designed database makes it possible to execute export / import of high volume data and retrieval of specific information in no time.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                            <div className=" rounded-xl w-[18rem] h-[18rem] mx-auto bg-gradient-to-r  pb-[6px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7]">
                                <div className="flex flex-col  h-[17.8rem] bg-white text-black  p-4">
                                <div className='flex justify-center'>
                                        <img src={Cross3} className="w-[30%]"/>
                                    </div>

                                    <div className="mt-10">
                                        <h3 className="text-[#808D9A] font-normal lg:text-[17px] md:text-[17px] text-[16px] mb-1 text-center">32 bit seamless front end and database integration makes it possible to transfer data between branches, head office and web server simple and dynamic.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 p-4 w-full">
                            <div className=" rounded-xl  w-[18rem] h-[18rem] mx-auto bg-gradient-to-r  pb-[6px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7]">
                                <div className="flex flex-col h-[17.8rem] bg-white text-black p-4">
                                <div className='flex justify-center'>
                                        <img src={Cross4} className="w-[30%]"/>
                                    </div>
                                    <div className="mt-10">
                                        <h3 className="text-[#808D9A] font-normal lg:text-[17px] md:text-[17px] text-[16px] mb-1 text-center">Considering the nature of business and amount of risk involved, security of data and prevention of mischief has been given priority over other factors.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Technology