import React from 'react'
import DataGrid, {
    Column,
    LoadPanel,
    Grouping,
    GroupPanel,
    Selection,
    Pager,
    Paging,
    SearchPanel,
  } from "devextreme-react/data-grid";
const CommexTab = ({data}) => {

    function createData(Branch, Commex, ClearingID, apipre, apiprep, apibranch) {
        return { Branch, Commex, ClearingID, apipre, apiprep, apibranch };
      }
      const rows = [
        createData("Off line Branches", "NCDEX", "", "Ncdex", "NcdexClgID", "OffLineBranches"),
        createData("CommNet", "MCX", "", "Mcx", "McxClgID", "CommNet"),
        createData("Website", "NSEL", "", "Nsel", "NselClgID", "Website"),
        createData("eSign", "AHM-NMCE", "", "AhmNmce", "AhmNmceClgID", "ESign"),
        createData("", "ICEX", "", "Icex", "IcexClgID"),
        createData("", "NSX", "", "Nsx", "NsxClgID"),
        createData("", "ACE", "", "Ace", "AceClgID"),
      ];

    const column1 = (row) => {
        return ( 
            <th className="pt-2">
                  <div className="flex-inline items-center align-middle">
                    <div className="px-1 inline-block">
                      <input
                        // disabled
                        id="default-checkbox"
                        name={row.Commex}
                        type="checkbox"
                        checked={
                          data?.[`${row.apipre}`] == "Y"
                        }
                        value=""
                        className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded"
                        // onChange={handleChange2}
                      />
                    </div>
                    <div className="p-2 text-start text-[#3D3D3D] inline-block">
                      {row.Commex}
                    </div>
                  </div>
                </th>
        )
      }

      const column2 = (row) => {
        return ( <>
            <div className="p-2">
            <div className="flex justify-start ">
              <input disabled
                type="text"
                value={data?.[row.apiprep]}
                id="visitors"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer      "
                placeholder=""
                required
              />
            </div>
            </div>
            </>
        )
      }

    
  return (
    <>
      <div>
        <div className="px-[1px]">
            <div className="flex items-center justify-center py-3 header-style">
                <input
                    checked={data?.Commex == "Y"}
                    className="w-[18px] h-[18px] mr-[5px] items-center"
                    type="checkbox" 
                />
                <div className="text-lg font-medium normal-case">Commex</div>
            </div>
        </div>
      </div>

      <div className=''>
        <DataGrid
          dataSource={rows}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          showBorders={true}
          keyExpr="Branch"
          >
          <Column
            dataField="Branch"
            caption=""
            // hidingPriority={0}
            dataType="string"
            alignment="center"
            cellRender={(e) => column1(e.data)}
          />
          <Column
            dataField="ClearingId"
            caption="Clearing Id"
            // hidingPriority={1}
            dataType="string"
            alignment="center"
            cellRender={(e) => column2(e.data)}
          />
          
        </DataGrid>
      </div>
    </>
  )
}

export default CommexTab