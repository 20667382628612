import React from 'react'
import './accounts.css';

function Accounts() {
    return (
        <>
            <section className="text-gray-600 body-font bg-[#F4FAFF]">
                <div className="container px-5 py-12 mx-auto">
                    <div className="flex flex-col text-center w-full mb-10">
                        <h2 className='title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-0 pb-0 text-[#0A2A4A]'>Accounts</h2>
                    </div>
                    <div className="flex flex-wrap -m-2">
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center lg:p-4 md:p-4 p-0 ">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute" width="100" height="100" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg><span className="text-gray-500 circle-text">01</span>
                                </div>
                                <div className="flex-grow ml-2 w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] font-small p-4 bg-[#ffffff]">A fully equipped accounting module that can handle all your accounting needs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center  lg:p-4 md:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute" width="100" height="100" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg><span className="text-gray-500 circle-text">06</span>
                                </div>
                                <div className="flex-grow w-[75%]" >
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] font-small lg:ml-7 md:ml-6 ml-2 p-4 bg-[#ffffff]">Managing multiple years A/c simultaneously with automatic update of balances from previous year into new year.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center  lg:p-4 md:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute" width="100" height="100" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg><span className="text-gray-500 circle-text">02</span>
                                </div>
                                <div className="flex-grow ml-3 w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] font-small p-4 bg-[#ffffff]">Various document like Receipt, Payments, Debit Notes, Credit Notes, Journals, Petty Cash Book etc. with facility to print vouchers and cheques..</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center lg:p-4 md:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute" width="100" height="100" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg><span className="text-gray-500 circle-text">07</span>
                                </div>                                
                                <div className="flex-grow w-[75%] ml-6">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] font-small p-4 bg-[#ffffff]"> Petty cash book in columnar format.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center  lg:p-4 md:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute" width="100" height="100" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg><span className="text-gray-500 circle-text">03</span>
                                </div>                               
                                <div className="flex-grow w-[75%] ml-3">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] font-small  p-4 bg-[#ffffff]">Bank reconciliation with powerful options to simplify the entire operation.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center  lg:p-4 md:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute" width="100" height="100" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg><span className="text-gray-500 circle-text">08</span>
                                </div>                                 
                                <div className="flex-grow w-[75%] ml-6">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] font-small p-4 bg-[#ffffff]"> Accounts confirmations letters, Interest Calculations etc.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center lg:p-4 md:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute" width="100" height="100" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg><span className="text-gray-500 circle-text">04</span>
                                </div>                                 
                                <div className="flex-grow w-[75%] ml-3">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] font-small p-4 bg-[#ffffff]"> Trial balance and funds positions with various options.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center lg:p-4 md:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute" width="100" height="100" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg><span className="text-gray-500 circle-text">09</span>
                                </div>
                                <div className="flex-grow ml-[21px] w-[75%] ">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] font-small p-4 bg-[#ffffff]">Payment entries of payout may be generated automatically based on amount to payout.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center lg:p-4 md:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute" width="100" height="100" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg><span className="text-gray-500 circle-text">05</span>
                                </div>
                                <div className="flex-grow ml-2 w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] font-small p-4 bg-[#ffffff]">Drilling down to actual entry / Bill from trial balance or ledger is possible. Modification of voucher also possible from here.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Accounts