import React,{useState, useEffect} from 'react'
import Slider from "react-slick";

const RightImage = require("./img/right-side-circle.png");
const LeftImage = require("./img/left-side-circle.png");
const center = require("./img/center.png");
// const BackArrow = (props) => {
//     return (
//       <button
//         type="button"
//         onClick={props.onClick}
//         className="back-forword-btn bg-white -m-8 absolute z-50	 ext-blue-700 border border-blue-700 hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-[18px] p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 left-[3%] top-[65%]"
//       >
//         <svg
//           className="stroke-[#3491ff] hover:stroke-[#ffffff]  "
//           width="25"
//           height="25"
//           viewBox="0 0 21 21"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297"
//             stroke-width="2"
//             stroke-miterlimit="10"
//             stroke-linecap="round"
//             stroke-linejoin="round"
//           />
//         </svg>
//       </button>
//     );
//   };
//   const ForwardArrow = (props) => {
//     return (
//       <button
//         type="button"
//         onClick={props.onClick}
//         className="back-forword-btn bg-white -ml-5 z-50 text-blue-700 border border-blue-700 hover:bg-[#3491FF] hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-[18px] p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute right-[.5%] top-[60%]"
//       >
//         <svg
//           className="stroke-[#3491ff] hover:stroke-[#ffffff]"
//           width="25"
//           height="25"
//           viewBox="0 0 21 21"
//           fill="none"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297"
//             stroke-width="2"
//             stroke-miterlimit="10"
//             stroke-linecap="round"
//             stroke-linejoin="round"
//           />
//         </svg>
//       </button>
//     );
//   };

  const BackArrow = (props) => {
    return (
      <button type="button" onClick={props.onClick} className="stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-4 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 left-[2%] top-[50%]">
        <svg width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    )
  }

  const ForwardArrow = (props) => {
    return (
      <button type="button" onClick={props.onClick} className="stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-4 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 right-[2%] top-[50%]">
        <svg className="" width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    )
  }

export default function MobileSlider() {

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // infinite: true,
        // autoplaySpeed: 3000,
        // autoplay: true,
        nextArrow: <ForwardArrow />,
        prevArrow: <BackArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
      const [windowSize, setWindowSize] = useState(getWindowSize());

      useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);
      function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }
    

  return (
    <>
        {/* MobileSlider */}

        <div className='mobile-slider'>

        <Slider {...settings}>
            <div className="relative lg:w-[30rem] md:w-[30rem] sm:w-[10rem] justify-center items-center flex top-[5rem] " >
                <img className="w-full absolute h" alt="img" src={LeftImage} />
                <div className="text absolute z-10  text-center text-ellipsis overflow-hidden mx-[19%] left-[-8%] top-[3rem]">
                <p className="font-medium text-[16px] md:text-[17px] text-[#808D9A]">
                    For each slab a Minimum / Percent / Maximum / Fixed
                    brokerage may be defined.
                </p>
                </div>
            </div>
            <div className="relative  lg:w-[30rem] md:w-[30rem] sm:w-[10rem] justify-center items-center flex z-10">
                <img className="w-full absolute" alt="img" src={center} />
                <div className="text absolute z-10  text-center text-ellipsis overflow-hidden mx-[19%] top-[4rem]">
                <p className="font-mediu text-[16px] md:text-[17px] text-[#808D9A]">
                    {" "}
                    Minimum Brokerage per contract may also be defined.{" "}
                </p>
                </div>
            </div>
            <div className="relative  lg:w-[30rem] md:w-[30rem] sm:w-[10rem] justify-center items-center flex top-[5rem] ">
                <img className="w-full absolute" alt="img" src={RightImage} />
                <div className="text absolute z-10 text-center text-ellipsis overflow-hidden mx-[19%] right-[-8%] top-[5rem]">
                <p className="font-mediu text-[16px] md:text-[17px] text-[#808D9A]">
                    Speculation, Squaring off and delivery brokerages charged
                    can be defined for same client.
                </p>
                </div>
            </div>

            <div className="relative  lg:w-[30rem] md:w-[30rem] sm:w-[10rem] justify-center items-center flex  top-[5rem] ">
                <img className="w-full absolute" alt="img" src={LeftImage} />
                <div className="text absolute z-10 text-center text-ellipsis overflow-hidden  mx-[19%] left-[-8%] top-[4rem]">
                <p className="font-medium text-[16px] md:text-[17px] text-[#808D9A]">
                    Brokerage discounts may be offered based on daily
                    turnover.
                </p>
                </div>
            </div>
            {/*     width: 56%;
    font-size: 17px;
    left: 83px;
    top: 54px;
    height: 130px;
    overflow-y: auto;
     */}
            <div className="relative lg:w-[30rem] md:w-[30rem] sm:w-[10rem] justify-center items-center flex z-5  ">
                <img className="w-full absolute z-1" alt="img" src={center} />
                {/* <div className="text absolute z-10 max-h-[25%]  text-center text-ellipsis overflow-auto   mx-auto" > */}
                <p className="absolute font-medium  z-10 text-[16px] md:text-[18px] text-[#808D9A]
                    h-[130px]
                    w-[56%]
                    top-[34px]
                    left-[72px]
                    overflow-x-auto
                ">
                    Sharing of brokerage based on
                    percentage of brokerage
                    charged to client or on the
                    basis of excess charge over
                    a decided base brokerage.
                    Report is also available to check
                    if any such sharing results in
                    negative income of the broker
                </p>
                {/* </div> */}
            </div>
    
            <div className="relative  lg:w-[30rem] md:w-[30rem] sm:w-[10rem] justify-center items-center flex  top-[5rem] ">
                <img className="w-full absolute" alt="img" src={RightImage} />
                <div className="text absolute  z-10 w-[64%] top-[46px] h-[155px]  text-center text-ellipsis overflow-auto  ml-[10%] right-[8%]"  >
                <p className="font-medium text-[16px] md:text-[18px] text-[#808D9A]">
                    Facility to introduce crediting the brokerage of Individuals to their respective accounts along with statements posting or calculated periodically
                </p>
                </div>
            </div>
        </Slider>

        </div>
    </>
  )
}
