import React from "react";

function Footer() {
  return (
    <>
      <footer className="text-gray-600 body-font bg-[#0A2A4A]">
        <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
          <div className="flex-grow flex flex-wrap md:pr-20 -mb-10 md:text-left text-center order-first">
            <div className="lg:w-2/4 md:w-1/2 w-full px-5">
              <h2 className="title-font">
                <img
                  className="fill-current object-cover h-30 w-40 mb-6"
                  src={require("../Images/logo.png")}
                />
              </h2>
              <nav className="list-none md:mb-10">
                <li>
                  <div className="text-[#ffff] font-medium text-lg mt-3">
                    Secmark Consultancy Limited.
                  </div>
                </li>
                <li className="mt-5">
                  <div className="text-[#FFFFFF] font-small text-md">
                    36/227, RDP 10, Sector VI, Charkop, Kandivali (W), Mumbai,
                    Maharashtra - 400 067, India.
                  </div>
                </li>
              </nav>
            </div>
            <div className="lg:w-2/4 md:w-2/2 w-full px-4 mt-20">
              <h1 className="text-lg font-small title-font text-[#00C673] text-center justify-center">
                Technology For Commerce
              </h1>
            </div>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-5">
            <h2 className="title-font font-medium text-[#3491FF] tracking-widest text-lg mb-5 border-l-4 border-inherit pl-4 ">
              Contact us
            </h2>
            <div className="flex-col-reverse block xl:flex-wrap md:flex-wrap lg:flex-wrap flex-wrap justify-center items-end md:justify-start">
              <button className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-white bg-[#123961] items-end border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">
                <img
                  className="fill-current px-3 "
                  src={require("../Images/Calling.png")}
                />
                <div>+91 9920189071</div>
              </button>
              </div>
              <div className="flex-col-reverse block xl:flex-wrap md:flex-wrap lg:flex-wrap flex-wrap justify-center items-end md:justify-start">
              <button className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-white bg-[#123961] items-end border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">
                <img
                  className="fill-current px-3 "
                  src={require("../Images/Calling.png")}
                />
                <div>022 46059890</div>
              </button>
            </div>
            <div className="flex-col-reverse block xl:flex-wrap md:flex-wrap lg:flex-wrap flex-wrap justify-center items-end md:justify-start mt-3">
              <button className="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex items-center text-white bg-[#123961]  border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">
                <img
                  className="fill-current px-3 "
                  src={require("../Images/Message.png")}
                />
                <a
                  href="mailto:info@secmark.in"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  info@secmark.in
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="bg-[#0A2A4A] sm:border-t-2 sm:border-[#FFFFFF]">
          <div className="container mx-auto py-4 flex flex-wrap flex-col sm:flex-row">
            <p className="text-[#fff] text-sm text-center sm:text-left ml-10">
              2020 Copyright :
              <a
                href="#"
                rel="noopener noreferrer"
                className="text-[#3491FF] ml-1"
                target="_blank"
              >
                {" "}
                info@secmark.in
              </a>
            </p>

            <div className="lg:w-3/4 md:w-2/2 w-full px-5 flex justify-end mb-9">
              <nav className="list-none flex-row ">
                <li className="inline pr-5">
                  <a className="text-[#ffffff] font-small text-md">
                    Disclaimer
                  </a>
                </li>
                <li className="inline pl-4">
                  <a className="text-[#FFFFFF] font-small text-md">
                    Privacy Policy.
                  </a>
                </li>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
